import produce from "immer";
import actionTypes from "../Actions/types";

const initialState = {
    portfolios: [],
    selectedPortfolio: {},
    coinList: [],
};

const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.UPDATE_BACKEDUP_PORTFOLIO:
                draft.portfolios = action.payload;
                break;
            case actionTypes.ADD_PORTFOLIO:
                draft.portfolios.splice(0, 0, action.payload);
                return draft;
            case actionTypes.SET_SELECTED_PORTFOLIO:
                draft.selectedPortfolio = action.payload;
                break;
            case actionTypes.CACHE_COIN_LIST:
                draft.coinList = action.payload;
                break;
            case actionTypes.DELETE_PORTFOLIO:
                const { uid } = action.payload;
                draft.portfolios = draft.portfolios.filter(portfolio=> uid !== portfolio.uid);
                break;
            default:
                return draft;
        }
    });

export default reducer;

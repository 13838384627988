import { notification } from "antd";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "./init";

export const performBackup = async (
    collectionName,
    userInfo,
    portfolios,
    assets,
    settings
) => {
    notification.info({
        message: `In Progress`,
        description: `Syncing your data`,
        key: "backup-in-progress",
    });
    const docName = `${userInfo.name.firstName}-${userInfo.name.lastName}-${userInfo.uid}`;
    try {
        await setDoc(
            doc(db, collectionName, docName),
            {
                username: userInfo.uid,
                name: userInfo.name,
                picture: userInfo.picture,
                portfolios,
                assets,
                settings,
            },
            { merge: true }
        );
        notification.close("backup-in-progress");
        notification.success({ message: `Done`, description: `Syned up!` });
    } catch (e) {
        console.error("Error backing up ", e);
    }
};

export const fetchBackup = async (collectionName, userInfo) => {
    notification.info({
        info: "In progress",
        message: "Fetching cloud data",
        key: "fetch-backup-inprogress",
    });
    const docName = `${userInfo.name.firstName}-${userInfo.name.lastName}-${userInfo.uid}`;

    const docRef = doc(db, collectionName, docName);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        notification.close("fetch-backup-inprogress");
        notification.success({
            message: "Sync complete!",
            description:
                "Found your data on cloud, your local data has been updated.",
        });
        return docSnap.data();
    }
    notification.info({
        message: "Ah Snap!",
        description: "No backup to Sync!",
    });
    return null;
};

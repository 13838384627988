import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { getFormattedAmount, getFormattedPercentage } from "Shared/Utils";
import Title from "antd/lib/typography/Title";
import { Button, Progress } from "antd";
import { Colors } from "Shared/Palletes";
import getCurrencySymbol from "Shared/CurrancySymbol";
// import CoinGeckoLogo from "utils/images/CoinGeckoLogo.png";

const OverviewCard = (props) => {
    const {
        itemInfo = {
            name: "",
            uid: "",
            avatar: "",
            handleMoreClk: () => {},
        },
        itemData = {
            currentValue: 0,
            pl: 0,
        },
        currency = "INR",
        index = 0,
    } = props;
    const { to, from } =
        Colors[Math.ceil(index > 9 ? 9 * Math.ceil(index / 9) - index : index)];
    return (
        <div key={itemInfo.uid} style={{ marginBottom: "1.5rem" }}>
            <div
                style={{
                    padding: "1rem",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 2px",
                    backgroundColor: from,
                    background: `linear-gradient(to right, ${from}, ${to})`,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <Title
                            level={3}
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {/* {itemInfo?.avatar?.includes("http") ? (
                                <img
                                    src={itemInfo.avatar || CoinGeckoLogo}
                                    alt="coin icon"
                                    width="20px"
                                    style={{
                                        border: "2px solid #fff",
                                        borderRadius: "50%",
                                    }}
                                />
                            ) : null} */}
                            {/* nbsp; is 160 */}
                            {/* {itemInfo?.avatar?.includes("http") &&
                                String.fromCharCode(160)} */}
                            {itemInfo.name}
                        </Title>
                    </div>
                    <Button
                        style={{
                            paddingRight: 0,
                            fontWeight: 600,
                            color: "whitesmoke",
                        }}
                        type="link"
                        onClick={() => {
                            itemInfo.handleMoreClk(itemInfo.uid);
                        }}
                        tabIndex="0"
                    >
                        view <ArrowRightOutlined />
                    </Button>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <div>Current Value</div>
                        <div>
                            <Title level={4}>
                                {getCurrencySymbol(currency)}
                                &nbsp;
                                {getFormattedAmount(itemData.currentValue)}
                            </Title>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "flex-end",
                            flexDirection: "column",
                        }}
                    >
                        <div>Porfit/Loss</div>
                        <Title level={4}>
                            {getFormattedPercentage(itemData.pl)}
                        </Title>
                    </div>
                </div>
            </div>
            {itemData.investmentPie && (
                <Progress
                    percent={Math.floor(itemData.investmentPie * 100)}
                    strokeColor={{
                        "0%": from,
                        "100%": to,
                    }}
                />
            )}
        </div>
    );
};

export default OverviewCard;

import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as assetAction from "store/Actions/asset";
import { getFormattedAmount } from "Shared/Utils";
import { mpTapPreview } from "Shared/Mixpanel/AssetsManager";

const PortfolioOnBuy = (props) => {
    const { coinInfo = {} } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedPortfolio = useSelector(
        (state) => state.portfolios.selectedPortfolio
    );
    const asset = useSelector((state) => state.assets.selectedAsset);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({
        currentInvestment: asset.portfolio?.latest?.investment || 0,
        averagePPC: asset.portfolio?.latest?.marketPrice || 0,
        marketPrice: coinInfo.current_price || 0,
    });
    const [disabled, setDisabled] = useState(true);
    const handleValueChange = (value) => {
        const _formData = {
            ...formData,
            ...value,
        };
        if (
            _formData.currentInvestment === 0 &&
            _formData.averagePPC === 0 &&
            _formData.numberOfTokens &&
            _formData.marketPrice
        ) {
            setDisabled(false);
        } else {
            setDisabled(
                !_formData.currentInvestment ||
                    !_formData.averagePPC ||
                    !_formData.numberOfTokens ||
                    !_formData.marketPrice
            );
        }
        setFormData(_formData);
    };
    const onFinish = (values) => {
        const currentInvestment = Number(values.currentInvestment);
        const averagePPC = Number(values.averagePPC);
        const newInvestment =
            Number(values.numberOfTokens) * Number(values.marketPrice);
        const marketPrice = Number(values.marketPrice);
        dispatch(
            assetAction.buy({
                currentInvestment,
                averagePPC,
                newInvestment,
                marketPrice,
            })
        );
        mpTapPreview({ type: "buy" });
        history.push(
            `/portfolios/${selectedPortfolio.name}/assets/${asset.id}/preview/buy`
        );
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    currentInvestment: getFormattedAmount(
                        asset.portfolio?.latest?.investment || 0,
                        false
                    ),
                    averagePPC: getFormattedAmount(
                        asset.portfolio?.latest?.marketPrice || 0,
                        false
                    ),
                    marketPrice: getFormattedAmount(
                        coinInfo.current_price || 0,
                        false
                    ),
                }}
                onValuesChange={handleValueChange}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                name="get-portfolio-on-buy"
            >
                <Form.Item
                    label="Current Investment"
                    name="currentInvestment"
                    value={formData.currentInvestment}
                    step="any"
                    min="0"
                    hidden={formData.currentInvestment === 0}
                >
                    <Input
                        placeholder=""
                        type="number"
                        size="large"
                        style={{ fontSize: "24px" }}
                    />
                </Form.Item>
                <Form.Item
                    label="Average Price Per Share (PPS)"
                    name="averagePPC"
                    value={formData.averagePPC}
                    step="any"
                    min="0"
                    hidden={formData.averagePPC === 0}
                >
                    <Input
                        placeholder=""
                        type="number"
                        size="large"
                        style={{ fontSize: "24px" }}
                    />
                </Form.Item>
                <Form.Item label="Number of Tokens/Coins" name="numberOfTokens">
                    <Input
                        placeholder=""
                        type="number"
                        value={formData.numberOfTokens}
                        step="any"
                        min="0"
                        size="large"
                        style={{ fontSize: "24px" }}
                    />
                </Form.Item>
                <Form.Item label="Current Market Price" name="marketPrice">
                    <Input
                        placeholder=""
                        type="number"
                        value={formData.marketPrice}
                        step="any"
                        min="0"
                        size="large"
                        style={{ fontSize: "24px" }}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        disabled={disabled}
                        type="primary"
                        htmlType="submit"
                        block
                        size="large"
                    >
                        Preview Buy
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default PortfolioOnBuy;

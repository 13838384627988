import React, { useEffect } from "react";
import { Button, Drawer, Table } from "antd";
import Title from "antd/lib/typography/Title";
import { getFormattedAmount } from "Shared/Utils";
import { PerformanceTag } from "Shared/PerformanceTag";
import { DownOutlined, ReloadOutlined, UpOutlined } from "@ant-design/icons";

const InvestmentList = (props) => {
    const {
        investments = [],
        headerStats = {},
        onClose = () => {},
        visible = false,
        refreshing = false,
        getAllPortfolioAssetsInfo = () => {},
    } = props;

    const columns = [
        {
            title: "Coin",
            dataIndex: "name",
            key: "name",
            align: "left",
        },
        {
            title: "Investment",
            dataIndex: "amountView",
            key: "amountView",
            align: "right",
        },
    ];

    useEffect(() => {}, [headerStats.pl]);

    return (
        <div>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            <Title
                                level={4}
                                style={{
                                    marginBottom: 0,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                Overview
                            </Title>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-end",
                                flexDirection: "column",
                            }}
                        >
                            <div style={{ fontSize: "12px" }}>
                                Current Value
                            </div>
                            <div>
                                <Title level={5}>
                                    &#8377;&nbsp;
                                    {getFormattedAmount(
                                        headerStats.currentValue
                                    )}
                                    &nbsp;
                                    <span
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        {PerformanceTag(headerStats.pl, true)}
                                    </span>
                                </Title>
                            </div>
                        </div>
                    </div>
                }
                placement="bottom"
                closable={false}
                width="100%"
                height="80%"
                onClose={onClose}
                open={visible}
                bodyStyle={{ paddingTop: 0 }}
                headerStyle={{
                    padding: "1rem 1.5rem 0.5rem",
                }}
                footer={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "-webkit-fill-available",
                                paddingLeft: "10px",
                            }}
                        >
                            <div>Total Investment</div>
                            <Title
                                level={5}
                                style={{ margin: "0 0.25rem 0 0" }}
                            >
                                &#8377;&nbsp;
                                {getFormattedAmount(
                                    headerStats.totalInvestment
                                )}
                            </Title>
                        </div>
                        <Button
                            type="default"
                            onClick={() => getAllPortfolioAssetsInfo()}
                            style={{ marginLeft: 4, marginRight: 8 }}
                        >
                            <ReloadOutlined spin={refreshing} />
                        </Button>
                        <div>
                            <Button
                                onClick={onClose}
                                style={{ marginRight: 8 }}
                            >
                                {visible ? <DownOutlined /> : <UpOutlined />}
                            </Button>
                        </div>
                    </div>
                }
            >
                {investments.map((investment) => {
                    return (
                        <div style={{ padding: "1rem 0" }} key={investment.uid}>
                            <Title level={4}>{investment.portfolio}</Title>
                            <Table
                                columns={columns}
                                dataSource={investment.assets}
                                size="small"
                                bordered
                                pagination={false}
                            />
                        </div>
                    );
                })}
            </Drawer>
        </div>
    );
};

export default InvestmentList;

import React from "react";
import { Col, Divider, Drawer, Row, Select } from "antd";

const BinancePreferences = (props) => {
    const { onClose, open, sortBy, setSortBy } = props;

    const sortOptions = [
        {
            label: "Order by ROI",
            value: "roi",
        },
        {
            label: "Order by Investment",
            value: "investment",
        },
        {
            label: "Order by Current Value",
            value: "currentValue",
        },
        {
            label: "Order by Name",
            value: "name",
        },
        {
            label: "Order by Price",
            value: "price",
        },
        {
            label: "Order by Quantity",
            value: "quantity",
        },
    ];

    return (
        <Drawer
            title="Preferences"
            placement="bottom"
            height="30%"
            onClose={onClose}
            open={open}
        >
            <Row
                gutter={16}
                style={{
                    alignItems: "center",
                }}
            >
                <Col className="gutter-row" span={12}>
                    How do you want to see your assets?
                </Col>
                <Col className="gutter-row" span={12}>
                    <Select
                        value={sortOptions.find(
                            (option) => option.value === sortBy
                        )}
                        style={{
                            width: "100%",
                        }}
                        onChange={(value) => {
                            setSortBy(value);
                        }}
                        options={sortOptions}
                    />
                </Col>
            </Row>
            <Divider />
        </Drawer>
    );
};

export default BinancePreferences;

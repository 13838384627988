import React from "react";

const ImportedPortfolioCard = (props) => {
    const { name = "", logo = "" } = props;
    return (
        <React.Fragment style={{ marginBottom: "1.5rem", cursor: "pointer" }}>
            <div
                style={{
                    padding: "1rem",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 2px",
                    backgroundColor: "#323232",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <img src={logo} alt={name} height="100px" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ImportedPortfolioCard;

import actionTypes from "./types";

export const add = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.ADD_PORTFOLIO,
        payload,
    });
};

export const setSelectedPortfolio = (portfolio) => (dispatch) => {
    return dispatch({
        type: actionTypes.SET_SELECTED_PORTFOLIO,
        payload: portfolio,
    });
};

export const cacheCoinList = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.CACHE_COIN_LIST,
        payload,
    });
};

export const updateBackedupPortfolios = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.UPDATE_BACKEDUP_PORTFOLIO,
        payload,
    });
};

export const deletePortfolio = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.DELETE_PORTFOLIO,
        payload,
    });
};

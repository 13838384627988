import React from "react";
import { Tag } from "antd";
import { getFormattedAmount, getFormattedPercentage } from "./Utils";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

export const PerformanceTag = (value, isPercentage) => {
    return (
        <>
            &nbsp;
            <Tag
                style={{ marginRight: 0 }}
                color={value === 0 ? "default" : value > 0 ? "green" : "red"}
            >
                {isPercentage && getFormattedPercentage(value, 1)}
                {!isPercentage && getFormattedAmount(value, true)}
                {value !== 0 ? (
                    value > 0 ? (
                        <ArrowUpOutlined />
                    ) : (
                        <ArrowDownOutlined />
                    )
                ) : (
                    ""
                )}
            </Tag>
        </>
    );
};

import React from "react";
import { Statistic, Tag } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { getFormattedAmount, getFormattedPercentage } from "Shared/Utils";

const ChangeView = (props) => {
    const {
        title = "",
        previousValue = "",
        latestValue = "",
        isReverseSentiment = false,
        isSell = false,
    } = props;

    const isPositive = latestValue > previousValue;
    const percentageChange =
        ((latestValue - previousValue) / previousValue) * 100;
    return (
        <div>
            <Statistic
                title={title}
                value={getFormattedAmount(
                    isSell ? latestValue - previousValue : latestValue
                )}
                prefix={
                    isPositive ? <ArrowUpOutlined /> : <ArrowDownOutlined />
                }
                suffix={
                    <Tag
                        color={
                            (isReverseSentiment ? !isPositive : isPositive)
                                ? "green"
                                : "volcano"
                        }
                    >
                        {getFormattedPercentage(percentageChange)}
                    </Tag>
                }
                valueStyle={{
                    color: (isReverseSentiment ? !isPositive : isPositive)
                        ? "green"
                        : "#FA541C",
                }}
            />
        </div>
    );
};

export default ChangeView;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import emoji from "node-emoji";
import { nanoid } from "nanoid";
import { Form, Input, Button, Drawer, Row, Col } from "antd";
import Disclaimer from "Shared/Disclaimer";
import * as portfolioAction from "store/Actions/portfolio";
import { mpAddPortfolio } from "Shared/Mixpanel/Portfolios";

const AddPortfolio = (props) => {
    const { showAddPortfolioDrawer, onClose } = props;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState(true);
    const [portfolio, setPortfolio] = useState("");

    const handleValueChange = (value) => {
        setPortfolio(value.portfolioName);
        setDisabled(!value.portfolioName);
    };

    const onFinish = (values) => {
        setPortfolio("");
        onClose(false);
        mpAddPortfolio({
            name: values.portfolioName,
        });
        dispatch(
            portfolioAction.add({
                name: values.portfolioName,
                avatar: emoji.random().emoji,
                uid: nanoid(),
                createdAt: Date.now(),
            })
        );
    };

    const onFinishFailed = (error) => {
        console.log(`Error`, error);
    };

    return (
        <div>
            <Drawer
                title="Create Portfolio"
                width="100%"
                height="60%"
                placement="bottom"
                onClose={() => onClose()}
                open={showAddPortfolioDrawer}
            >
                <Row>
                    <Col sm={24} md={{ span: 8, offset: 8 }}>
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={{ portfolioName: portfolio }}
                            onValuesChange={handleValueChange}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            name="add-portfolio"
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Portfolio Name"
                                name="portfolioName"
                            >
                                <Input
                                    type="text"
                                    placeholder="WazirX / CoinSwitch"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    disabled={disabled}
                                    type="primary"
                                    htmlType="submit"
                                    block
                                >
                                    {`Add ${portfolio || ""}`}
                                </Button>
                            </Form.Item>
                            <Disclaimer />
                        </Form>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
};

export default AddPortfolio;

import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedAmount } from "Shared/Utils";
import { useHistory } from "react-router-dom";
import * as assetAction from "store/Actions/asset";
import { mpTapPreview } from "Shared/Mixpanel/AssetsManager";

const PortfolioOnSell = (props) => {
    const { coinInfo = {} } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedPortfolio = useSelector(
        (state) => state.portfolios.selectedPortfolio
    );
    const asset = useSelector((state) => state.assets.selectedAsset);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({
        currentInvestment: asset.portfolio?.latest?.investment || 0,
        averagePPC: asset.portfolio?.latest?.marketPrice || 0,
        // amount2Sell:
        //     coinInfo.current_price *
        //         asset.portfolio?.latest?.numberOfCoins || 0,
        coins2Sell: asset.portfolio?.latest?.numberOfCoins || 0,
        marketPrice: coinInfo.current_price,
    });
    const [disabled, setDisabled] = useState(true);

    const handleValueChange = (value) => {
        const _formData = {
            ...formData,
            ...value,
        };
        setDisabled(
            !_formData.currentInvestment ||
                !_formData.averagePPC ||
                !_formData.marketPrice
        );
        setFormData(_formData);
    };

    const onFinish = (values) => {
        const currentInvestment = Number(values.currentInvestment);
        const averagePPC = Number(values.averagePPC);
        // const amount2Sell = Number(values.amount2Sell);
        const coins2Sell = Number(values.coins2Sell);
        const marketPrice = Number(values.marketPrice);

        dispatch(
            assetAction.sell({
                currentInvestment,
                averagePPC,
                // amount2Sell,
                coins2Sell,
                marketPrice,
            })
        );
        mpTapPreview({ type: "sell" });
        history.push(
            `/portfolios/${selectedPortfolio.name}/assets/${asset.id}/preview/sell`
        );
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    preserve: true,
                    currentInvestment: asset.portfolio?.latest?.investment || 0,
                    averagePPC: asset.portfolio?.latest?.marketPrice || 0,
                    // amount2Sell: getFormattedAmount(
                    //     coinInfo.current_price *
                    //         asset.portfolio?.latest?.numberOfCoins,
                    //     false
                    // ),
                    coins2Sell:
                        getFormattedAmount(
                            asset.portfolio?.latest?.numberOfCoins,
                            false
                        ) || 0,
                    marketPrice: getFormattedAmount(
                        coinInfo.current_price,
                        false
                    ),
                }}
                onValuesChange={handleValueChange}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                name="get-portfolio-on-sell"
            >
                <Form.Item
                    label="Current Investment"
                    name="currentInvestment"
                    value={formData.currentInvestment}
                    step="any"
                    min="0"
                    size="large"
                    style={{ fontSize: "24px" }}
                >
                    <Input placeholder="" type="number" />
                </Form.Item>
                <Form.Item
                    label="Average Price"
                    name="averagePPC"
                    value={formData.averagePPC}
                    step="any"
                    min="0"
                    size="large"
                    style={{ fontSize: "24px" }}
                >
                    <Input placeholder="" type="number" />
                </Form.Item>
                {/* <div
                    style={{
                        border: "2px dashed #ccc",
                        borderRadius: "0.5rem",
                        padding: "1rem 0.5rem 0",
                        margin: "0 0 1rem",
                    }}
                > */}
                {/* <Form.Item label="Amount to Sell" name="amount2Sell">
                        <Input
                            placeholder=""
                            type="number"
                            value={formData.amount2Sell}
                            step="any"
                            min="0"
                        />
                    </Form.Item>
                    <Divider orientation="center" plain>
                        or
                    </Divider> */}
                <Form.Item label="Holdings to Sell" name="coins2Sell">
                    <Input
                        placeholder=""
                        type="number"
                        value={formData.coins2Sell}
                        step="any"
                        min="0"
                        size="large"
                        style={{ fontSize: "24px" }}
                    />
                </Form.Item>
                {/* </div> */}
                <Form.Item label="Market Price" name="marketPrice">
                    <Input
                        placeholder=""
                        type="number"
                        value={formData.marketPrice}
                        step="any"
                        min="0"
                        size="large"
                        style={{ fontSize: "24px" }}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        disabled={disabled}
                        type="primary"
                        htmlType="submit"
                        block
                        size="large"
                    >
                        Preview Sell
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default PortfolioOnSell;

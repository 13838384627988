import React, { useState } from "react";
import { Form, Input, Button, Drawer, Row, Col, notification } from "antd";
import Axios from "axios";
import Cookies from "js-cookie";

const AddBinance = (props) => {
    const { showConnectBinanceDrawer, onClose, getAssetInfo } = props;
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState(true);

    const handleValueChange = (value) => {
        setDisabled(!value.apiKey && !value.apiSecret);
    };

    const onFinish = async (values) => {
        try {
            const api = `${process.env.REACT_APP_API}/binance/auth/connect`;
            await Axios.post(api, values, {
                headers: {
                    Authorization: `Bearer ${Cookies.get(
                        "me_apps_access_token"
                    )}`,
                },
            });
            getAssetInfo();
            onClose();
        } catch (error) {
            notification.error({
                message: `There was an error connecting to Binance`,
                description: `We have informed the developers. Please try again later.`,
                key: "binance-connection-failed",
            });
        }
    };

    const onFinishFailed = (error) => {
        console.log(`Error`, error);
    };

    return (
        <div>
            <Drawer
                title="Connecting Binance"
                width="100%"
                placement="right"
                open={showConnectBinanceDrawer}
                onClose={() => onClose()}
            >
                <Row>
                    <Col xs={24} md={{ span: 8, offset: 8 }}>
                        <Form
                            form={form}
                            layout="vertical"
                            onValuesChange={handleValueChange}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            name="connectBinanceForm"
                            autoComplete="off"
                        >
                            <Form.Item label="API key" name="apiKey">
                                <Input type="text" placeholder="Your API key" />
                            </Form.Item>
                            <Form.Item label="API Secret" name="apiSecret">
                                <Input
                                    type="text"
                                    placeholder="Your API Secret"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    disabled={disabled}
                                    type="primary"
                                    htmlType="submit"
                                    block
                                >
                                    Connect Binance
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
};

export default AddBinance;

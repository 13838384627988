import React from "react";

const getCurrencySymbol = (currency) => {
    switch (currency) {
        case "INR":
            return <span>{String.fromCodePoint(8377)}</span>;
        case "USD":
            return <span>{String.fromCodePoint(36)}</span>;
        default:
            return <span>{String.fromCodePoint(8377)}</span>;
    }
};

export default getCurrencySymbol;

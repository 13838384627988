import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import mixpanel from "mixpanel-browser";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./store/index.js";

import "./index.less";

import * as serviceWorker from "./serviceWorker";
import Homepage from "Pages/Homepage";
import Errorpage from "Pages/errorpage";
import AssetManager from "Pages/AssetManager";
import PortfolioManager from "Pages/PortfolioManager";
import AddAssetPage from "Pages/AssetManager/create.js";
import Result from "Components/Drawers/Result.js";
import BinancePortfolio from "Components/ListView/Binance.js";
import News from "Components/Forms/News.js";

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Homepage} />
                    <Route exact path="/portfolios" component={Homepage} />
                    <Route
                        exact
                        path="/portfolios/:name"
                        component={PortfolioManager}
                    />
                    <Route
                        exact
                        path="/portfolios/:name/assets/create"
                        component={AddAssetPage}
                    />
                    <Route
                        exact
                        path="/portfolios/:name/assets/:id"
                        component={AssetManager}
                    />
                    <Route
                        exact
                        path="/portfolios/:name/assets/:id/preview/:type"
                        component={Result}
                    />
                    <Route
                        exact
                        path="/imported/binance"
                        component={BinancePortfolio}
                    />
                    <Route
                        exact
                        path="/imported/binance/ai-news"
                        component={News}
                    />
                    <Route component={Errorpage} />
                </Switch>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import mixpanel from "mixpanel-browser";

export const mpTapLoginCTA = (options) => {
    mixpanel.track("TAP_LOGIN_CTA", options);
};

export const mpTapLogoutCTA = (options) => {
    mixpanel.track("TAP_LOGOUT_CTA", options);
};

export const mpTapSyncCTA = (options) => {
    mixpanel.track("TAP_SYNC_CTA", options);
};

export const mpTapOverrideCTA = (options) => {
    mixpanel.track("TAP_OVERRIDE_CTA", options);
};

export const mpTapLocalOverrideCTA = (options) => {
    mixpanel.track("TAP_LOCAL_OVERRIDE_CTA", options);
};

export const mpTapViewMoreCTA = (options) => {
    mixpanel.track("TAP_VIEW_MORE_CTA", options);
};

export const mpTapCreatePortfolioCTA = (options) => {
    mixpanel.track("TAP_CREATE_PORTFOLIO_CTA", options);
};

export const mpAddPortfolio = (options) => {
    mixpanel.track("ADD_PORTFOLIO", options);
};

export const mpTapRefreshCTA = (options) => {
    mixpanel.track("TAP_REFRESH_CTA", options);
};

export const mpTapInvestmentListViewCTA = (options) => {
    mixpanel.track("TAP_VIEW_INVESTMENT_LIST_CTA", options);
};

export const mpTapSettingsCTA = (options) => {
    mixpanel.track("TAP_SETTINGS_CTA", options);
};

export const mpTapDeletePortfolio = (options) => {
    mixpanel.track("DELETE_PORTFOLIO_CTA", options);
};

export const mpTapChangeCurrency = (options) => {
    mixpanel.track("TAP_CHANGE_CURRENCY_CTA", options);
};

export const howManyDecimals = (numberOfDecimals, value) => {
    if (numberOfDecimals) {
        // specified decimals already
        return numberOfDecimals;
    }
    if (value >= 1) {
        return 2;
    }
    if (Number(value.toFixed(6)) <= 0.000001) {
        return 8;
    }
    return 6;
};
export const getFormattedAmount = (
    value = "",
    shouldFormat = true,
    numberOfDecimals
) => {
    if (shouldFormat) {
        return value.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: howManyDecimals(numberOfDecimals, value),
        });
    }
    return Number(value.toFixed(howManyDecimals(numberOfDecimals, value)));
};

export const getFormattedPercentage = (value = "", numberOfDecimals) => {
    // eslint-disable-next-line
    return `${value === 0 ? value : value > 0 ? "+" : "-"} 
        ${Math.abs(value).toLocaleString("en-IN", {
            minimumFractionDigits: 1,
            maximumFractionDigits:
                numberOfDecimals || (Math.abs(value) >= 1 ? 2 : 4),
        })}%`;
};

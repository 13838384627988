import React from "react";
import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import { Row, Col, Tag } from "antd";
import { getFormattedAmount } from "Shared/Utils";

dayjs.extend(relativeTime);

const TransactionListView = (props) => {
    const { item } = props;
    const { createdAt, portfolioSnapshot, type } = item;
    const { latest, previous, transactionMarketPrice } = portfolioSnapshot;

    return (
        <div
            style={{
                width: "100%",
                marginBottom: "0.25rem",
                padding: "1rem",
                borderRadius: "8px",
                boxShadow: "0px 0px 2px",
            }}
        >
            <Row>
                <Col
                    span={24}
                    style={{
                        paddingRight: 8,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: 8,
                        }}
                    >
                        <span>Amount</span>
                        <span
                            style={{
                                fontWeight: 600,
                                color:
                                    latest.investment > previous.investment
                                        ? "green"
                                        : "red",
                            }}
                        >{`${getFormattedAmount(
                            latest.investment - previous.investment
                        )}`}</span>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>Coins</span>
                        <span
                            style={{
                                fontWeight: 600,
                                paddingBottom: 8,
                                color:
                                    latest.numberOfCoins >
                                    previous.numberOfCoins
                                        ? "green"
                                        : "red",
                            }}
                        >{`${
                            latest.numberOfCoins > previous.numberOfCoins
                                ? "+"
                                : "-"
                        }${getFormattedAmount(
                            latest.numberOfCoins > previous.numberOfCoins
                                ? latest.numberOfCoins - previous.numberOfCoins
                                : previous.numberOfCoins - latest.numberOfCoins
                        )}`}</span>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: 8,
                        }}
                    >
                        <span>Price</span>
                        <span
                            style={{
                                fontWeight: 600,
                            }}
                        >
                            {getFormattedAmount(transactionMarketPrice)}
                        </span>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>Type</span>
                        <span>
                            <Tag
                                color={type === "buy" ? "green" : "red"}
                                style={{
                                    fontWeight: 600,
                                }}
                            >
                                {type.toUpperCase()}
                            </Tag>
                        </span>
                    </div>
                </Col>
            </Row>
            <span
                style={{
                    fontSize: "12px",
                    opacity: 0.6,
                    float: "right",
                    paddingTop: "10px",
                }}
            >
                {dayjs(createdAt).fromNow()}
            </span>
        </div>
    );
};

export default TransactionListView;

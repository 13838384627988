import React, { useEffect, useState } from "react";
import { getFormattedAmount, getFormattedPercentage } from "Shared/Utils";
import { Collapse, Typography } from "antd";
import getCurrencySymbol from "Shared/CurrancySymbol";

const { Panel } = Collapse;
const { Title, Text } = Typography;

const AssetInWallet = (props) => {
    const [color, setColor] = useState({
        primary: "#d9d9d9",
        secondary: "#fafafa",
        sentiment: "neutral",
    });
    const {
        asset = {
            name: "",
            currentValue: 0,
        },
        index = 0,
        investment = 1,
        sortBy = "name",
    } = props;

    useEffect(() => {
        const roi =
            ((asset.currentValue - asset.history.investmentInUSDT) /
                asset.history.investmentInUSDT) *
            100;
        if (roi > 0) {
            setColor({
                primary: "#52c41a",
                secondary: "#237804",
                sentiment: "positive",
            });
        } else if (roi < 0) {
            setColor({
                primary: "#f5222d",
                secondary: "#a8071a",
                sentiment: "negative",
            });
        } else {
            setColor({
                primary: "#d9d9d9",
                secondary: "#fafafa",
                sentiment: "neutral",
            });
        }
    }, [asset]);

    const getHeaderRightContent = () => {
        if (sortBy === "roi") {
            return (
                <Text style={{ color: color?.primary }}>
                    {getFormattedPercentage(
                        ((asset.currentValue - asset.history.investmentInUSDT) /
                            asset.history.investmentInUSDT) *
                            100
                    )}
                </Text>
            );
        }
        if (sortBy === "currentValue") {
            return (
                <Text>
                    {getCurrencySymbol("USD")}
                    {getFormattedAmount(asset.currentValue)}
                </Text>
            );
        }
        if (sortBy === "investment") {
            return (
                <Text>
                    {getCurrencySymbol("USD")}
                    {getFormattedAmount(asset.history.investmentInUSDT)}
                </Text>
            );
        }
        if (sortBy === "price") {
            return (
                <Text>
                    {getCurrencySymbol("USD")}
                    {getFormattedAmount(asset.currentPrice)}
                </Text>
            );
        }

        return (
            <Text>
                {getCurrencySymbol("USD")}
                {getFormattedAmount(asset.currentPrice)}
            </Text>
        );
    };

    const getHeader = () => {
        return (
            <div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "1rem",
                        fontWeight: 600,
                    }}
                >
                    <Text>
                        {getFormattedAmount(asset.net.value)} {asset.name}
                    </Text>
                    {getHeaderRightContent()}
                </div>
            </div>
        );
    };
    return (
        <Collapse
            style={{
                border: `2px solid ${color?.primary}`,
                borderRadius: "4px",
                margin: "0px",
            }}
        >
            <Panel
                header={getHeader()}
                key={index}
                style={{ borderRadius: "4px" }}
            >
                <div>
                    <div
                        style={{
                            background: "#323232",
                            padding: "1rem",
                            // borderRadius: "8px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <div>Invested</div>
                                <Title level={4}>
                                    {getCurrencySymbol("USD")}
                                    {getFormattedAmount(
                                        asset.history.investmentInUSDT
                                    )}
                                </Title>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    flexDirection: "column",
                                }}
                            >
                                <div>Current Value</div>
                                <div>
                                    <Title level={4}>
                                        {getCurrencySymbol("USD")}
                                        {getFormattedAmount(asset.currentValue)}
                                    </Title>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <div>ROI</div>
                                <Title
                                    level={4}
                                    style={{ color: color?.primary }}
                                >
                                    {getFormattedPercentage(
                                        ((asset.currentValue -
                                            asset.history.investmentInUSDT) /
                                            asset.history.investmentInUSDT) *
                                            100
                                    )}
                                </Title>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    flexDirection: "column",
                                }}
                            >
                                <div>Average Buy</div>
                                <Title level={5}>
                                    {getCurrencySymbol("USD")}
                                    {getFormattedAmount(
                                        asset.history.averagePrice || 0
                                    )}
                                </Title>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <div>Holdings</div>
                                <Title level={5}>
                                    {(
                                        (asset.history.investmentInUSDT /
                                            Number(investment)) *
                                        100
                                    ).toFixed(0)}
                                    %
                                </Title>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    flexDirection: "column",
                                }}
                            >
                                <div>Spot/Earn Balance</div>
                                <Title level={5}>
                                    {asset.net.pie.spot}% / {asset.net.pie.earn}
                                    %
                                </Title>
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
        </Collapse>
    );
};

export default AssetInWallet;

import React from "react";
import * as dayjs from "dayjs";
import * as duration from "dayjs/plugin/duration";
import { Row, Col, Drawer, Button, Divider, Space, Radio } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { authApp } from "config";
import { fetchBackup, performBackup } from "utils/Firebase/backup";
import { updateBackedupPortfolios } from "store/Actions/portfolio";
import { updateBackedupAssets } from "store/Actions/asset";
import { updateSettings, updateLastBackup } from "store/Actions/settings";
import {
    mpTapLoginCTA,
    mpTapLogoutCTA,
    mpTapOverrideCTA,
    mpTapSyncCTA,
    mpTapLocalOverrideCTA,
    mpTapChangeCurrency,
} from "Shared/Mixpanel/Portfolios";
import CoinGeckoLogo from "utils/images/CoinGeckoLogo.png";
import { version } from "../../../package.json";

dayjs.extend(duration);

const Settings = (props) => {
    const dispatch = useDispatch();
    const { visible = false, onClose = () => {} } = props;
    const { userInfo } = useSelector((state) => state.users);
    const { portfolios } = useSelector((state) => state.portfolios);
    const { assets } = useSelector((state) => state.assets);
    const { lastBackup, theme } = useSelector((state) => state.settings);
    // const [allowedBackup, setAllowedBackup] = useState(false);
    // const daysSinceLastBackup = dayjs
    //     .duration(dayjs.duration(Date.now(), "d").subtract(lastBackedUp, "d"))
    //     .days();
    // if (daysSinceLastBackup >= 1) {
    //     setAllowedBackup(true);
    // }
    const handleBackup = async () => {
        if (userInfo) {
            const data = await fetchBackup("users", userInfo);
            if (data) {
                dispatch(updateBackedupPortfolios(data.portfolios));
                dispatch(updateBackedupAssets(data.assets));
                dispatch(updateSettings(data.settings));
            }
        }
        // setAllowedBackup(false);
    };

    const handleOverride = async () => {
        if (userInfo) {
            await performBackup("users", userInfo, portfolios, assets, {
                lastBackup,
                theme,
            });
            dispatch(updateLastBackup(Date.now()));
        }
    };

    const handleLocalOverride = async () => {
        if (userInfo) {
            const data = await fetchBackup("users", userInfo);
            if (data) {
                dispatch(updateBackedupPortfolios(data.portfolios));
                dispatch(updateBackedupAssets(data.assets));
                dispatch(updateSettings(data.settings));
            } else {
                console.log("couldn't get backup");
            }
            onClose();
        }
    };

    const handleLogout = () => {
        window.location.href = `${authApp}?logout=true&redirect_url=${window.location.origin}`;
        localStorage.clear();
    };

    const handleCurrencyChange = (e) => {
        mpTapChangeCurrency({ currency: e.target.value });
        dispatch(
            updateSettings({
                lastBackup,
                theme,
                currency: e.target.value,
            })
        );
    };
    return (
        <div>
            <Drawer
                title={<Title level={4}>Settings</Title>}
                placement="bottom"
                closable={false}
                width="100%"
                height="100%" // "400px"
                onClose={onClose}
                open={visible}
                bodyStyle={{ paddingTop: 0 }}
                headerStyle={{
                    padding: "1rem 1.5rem 0.5rem",
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>
                            <ArrowLeftOutlined /> Home
                        </Button>
                    </Space>
                }
            >
                <Row
                    style={{
                        padding: "1rem 0",
                        width: "-webkit-fill-available",
                    }}
                >
                    <Col md={{ span: 8, offset: 8 }} xs={24}>
                        <Row
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "-webkit-fill-available",
                            }}
                        >
                            <Col xs={{ span: 24 }}>
                                <Title level={5}>General</Title>
                                <p>
                                    {userInfo && Number(lastBackup) === 0 && (
                                        <small>Sync your portfolios now</small>
                                    )}
                                    {userInfo && Boolean(lastBackup) && (
                                        <small>
                                            Your backup was synced&nbsp;
                                            {dayjs(lastBackup).from()}
                                        </small>
                                    )}
                                    {!userInfo && (
                                        <small>
                                            Please login to create backup.
                                        </small>
                                    )}
                                </p>

                                {userInfo && Number(lastBackup) === 0 && (
                                    <Button
                                        block
                                        type="primary"
                                        onClick={() => {
                                            mpTapSyncCTA();
                                            handleBackup();
                                        }}
                                    >
                                        Sync
                                    </Button>
                                )}
                                {userInfo && Boolean(lastBackup) && (
                                    <Button
                                        block
                                        type="ghost"
                                        onClick={() => {
                                            mpTapLogoutCTA();
                                            handleLogout();
                                        }}
                                    >
                                        Logout
                                    </Button>
                                )}
                                {!userInfo && (
                                    <Button
                                        block
                                        type="primary"
                                        onClick={() => {
                                            mpTapLoginCTA();
                                            window.location.href = `${authApp}?redirect_url=${window.location.href}?login=1`;
                                        }}
                                    >
                                        Login
                                    </Button>
                                )}
                            </Col>
                            <Divider style={{ margin: "12px 0" }} />
                            <Col xs={{ span: 24 }}>
                                <Title level={5}>Currancy</Title>
                                <Radio.Group
                                    defaultValue="INR"
                                    buttonStyle="solid"
                                    style={{
                                        marginTop: 16,
                                    }}
                                    onChange={handleCurrencyChange}
                                >
                                    <Radio.Button value="INR">INR</Radio.Button>
                                    <Radio.Button value="USDT">
                                        USDT
                                    </Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Divider style={{ margin: "12px 0" }} />
                        {userInfo && (
                            <Row
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "-webkit-fill-available",
                                }}
                            >
                                <Col xs={{ span: 24 }}>
                                    <Title level={5}>
                                        Problem with data on this device?
                                    </Title>
                                    <p>
                                        <small>
                                            If your data is inconsistent on this
                                            device, override to get your local
                                            data updated. Make sure you have
                                            update the cloud data from other
                                            devices.
                                        </small>
                                    </p>

                                    <Button
                                        block
                                        type="primary"
                                        onClick={() => {
                                            mpTapLocalOverrideCTA();
                                            handleLocalOverride();
                                        }}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        Update Local Data
                                    </Button>
                                </Col>
                                <Divider style={{ margin: "12px 0" }} />
                                <Col xs={{ span: 24 }}>
                                    <Title level={5}>
                                        Problem with data on other devices?
                                    </Title>
                                    <p>
                                        <small>
                                            If your data is inconsistent across
                                            devices, override to share data on
                                            this device to all the devices.
                                            devices.
                                        </small>
                                    </p>

                                    <Button
                                        block
                                        type="primary"
                                        onClick={() => {
                                            mpTapOverrideCTA();
                                            handleOverride();
                                        }}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        Update Cloud Data
                                    </Button>
                                </Col>
                                <Divider style={{ margin: "12px 0" }} />
                            </Row>
                        )}
                        <Row
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "-webkit-fill-available",
                            }}
                        >
                            {/* <Col
                                xs={24}
                                align="center"
                                style={{
                                    padding: "0.5rem 0 0.5rem 0",
                                }}
                            >
                                <Button type="primary" shape="round" block>
                                    Download v{version}
                                </Button>
                            </Col> */}
                            v{version}
                        </Row>
                        <Divider style={{ margin: "12px 0" }} />
                        <Row
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "-webkit-fill-available",
                            }}
                        >
                            APIs powered by{" "}
                            <a
                                href="https://www.coingecko.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={CoinGeckoLogo}
                                    alt="coin gecko logo"
                                    height="40px"
                                />
                            </a>
                        </Row>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
};

export default Settings;

import mixpanel from "mixpanel-browser";

export const mpTapGoBack = (options) => {
    mixpanel.track("GO_BACK", options);
};

export const mpTapAddCoinCTA = (options) => {
    mixpanel.track("TAP_ADD_COIN_CTA", options);
};

export const mpTapViewMoreCTA = (options) => {
    mixpanel.track("TAP_VIEW_MORE_CTA", options);
};

export const mpTapAddCoin = (options) => {
    mixpanel.track("ADD_COIN", options);
};

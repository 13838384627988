import produce from "immer";
import { getFormattedAmount } from "Shared/Utils";
import actionTypes from "../Actions/types";

let initialState = {
    assets: [],
    selectedAsset: {},
    currentPortfolioAssets: [],
    holdMyResult: {},
};

let reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.UPDATE_BACKEDUP_ASSETS:
                draft.assets = action.payload;
                break;
            case actionTypes.ADD_ASSET:
                draft.assets = [...draft.assets, action.payload];
                break;
            case actionTypes.SET_PORTFOLIO_ASSETS:
                draft.currentPortfolioAssets = draft.assets.filter(
                    (asset) => asset.portfolioId === action.payload.uid
                );
                break;
            case actionTypes.SET_SELECTED_ASSET:
                draft.selectedAsset = action.payload;
                break;
            case actionTypes.HOLD_MY_RESULT:
                draft.holdMyResult = action.payload;
                break;
            case actionTypes.ADD_TRANSACTION:
                draft.assets = draft.assets.map((item) => {
                    if (item.uid === draft.selectedAsset.uid) {
                        let data = {
                            ...draft.selectedAsset,
                            transactions: draft.selectedAsset.transactions
                                ? [
                                      ...draft.selectedAsset.transactions,
                                      action.payload.transaction,
                                  ]
                                : [action.payload.transaction],
                            portfolio: action.payload.portfolio,
                        };
                        draft.selectedAsset = data;
                        return data;
                    } else {
                        return item;
                    }
                });
                break;

            case actionTypes.BUY:
                action.payload.currentInvestment = Number(
                    getFormattedAmount(action.payload.currentInvestment, false)
                );
                action.payload.averagePPC = Number(
                    getFormattedAmount(action.payload.averagePPC, false)
                );
                action.payload.newInvestment = Number(
                    getFormattedAmount(action.payload.newInvestment, false)
                );
                //don't change market price

                let isFirstInvestment =
                    action.payload.averagePPC === 0 &&
                    action.payload.currentInvestment === 0;
                let numberOfCoins = !isFirstInvestment
                    ? getFormattedAmount(
                          action.payload.currentInvestment /
                              action.payload.averagePPC,
                          false
                      )
                    : 0;
                let __numberOfCoins = Number(
                    getFormattedAmount(
                        action.payload.newInvestment /
                            action.payload.marketPrice,
                        false
                    )
                );
                let newTotalCoins = numberOfCoins + __numberOfCoins;
                let newTotalInvestment =
                    action.payload.currentInvestment +
                    action.payload.newInvestment;
                let _portfolio = {
                    previous: {
                        investment: action.payload.currentInvestment,
                        numberOfCoins,
                        marketPrice: action.payload.averagePPC,
                    },
                    latest: {
                        investment: newTotalInvestment,
                        numberOfCoins: newTotalCoins,
                        marketPrice: Number(
                            getFormattedAmount(
                                newTotalInvestment / newTotalCoins,
                                false
                            )
                        ),
                    },
                    transactionMarketPrice: action.payload.marketPrice,
                };
                draft.holdMyResult = _portfolio;
                break;

            case actionTypes.SELL:
                action.payload.currentInvestment = Number(
                    getFormattedAmount(action.payload.currentInvestment, false)
                );
                action.payload.averagePPC = Number(
                    getFormattedAmount(action.payload.averagePPC, false)
                );
                action.payload.marketPrice = Number(
                    getFormattedAmount(action.payload.marketPrice, false)
                );
                let _numberOfCoins =
                    action.payload.currentInvestment /
                    action.payload.averagePPC;
                _numberOfCoins = Number(
                    getFormattedAmount(_numberOfCoins, false)
                );
                // if (action.payload.amount2Sell) {
                //     action.payload.coins2Sell =
                //         action.payload.amount2Sell / action.payload.marketPrice;
                // } else
                // if (action.payload.coins2Sell) {
                //     action.payload.amount2Sell =
                //         action.payload.coins2Sell * action.payload.marketPrice;
                // } else {
                // }
                // previous = { investment: 16000, avg: 106, holdings: 138
                // latest = { investment: 16000 - 4400, avg: (16000 - 4400)/100, holdings: 100}
                // transactionMarketPrice = 116

                let amountToSell =
                    action.payload.coins2Sell * action.payload.marketPrice;
                amountToSell = Number(getFormattedAmount(amountToSell, false));
                let totalCoins = _numberOfCoins - action.payload.coins2Sell;
                totalCoins = Number(getFormattedAmount(totalCoins, false));
                const isExit = totalCoins === 0;
                //actual investment before profit or loss
                let newInvestment = totalCoins * action.payload.averagePPC;

                let portfolio = {
                    previous: {
                        investment: _numberOfCoins * action.payload.averagePPC,
                        numberOfCoins: _numberOfCoins,
                        marketPrice: action.payload.averagePPC,
                    },
                    latest: {
                        investment: isExit ? 0 : newInvestment,
                        numberOfCoins: totalCoins,
                        //on sell the average price of assets doesn't change
                        marketPrice: isExit ? 0 : action.payload.averagePPC,
                        returns: Number(
                            getFormattedAmount(
                                totalCoins * action.payload.marketPrice,
                                false
                            )
                        ),
                        sellInfo: {
                            amountToSell,
                            intialCapitalForPurchase:
                                action.payload.averagePPC *
                                action.payload.coins2Sell,
                        },
                    },
                    transactionMarketPrice: action.payload.marketPrice,
                };
                draft.holdMyResult = portfolio;
                break;
            default:
                return draft;
        }
    });

export default reducer;

import React from "react";
import { Row, Col } from "antd";
import AddAsset from "Components/Forms/AddAsset";

const AddAssetPage = () => {
    return (
        <div>
            <Row>
                <Col xs={{ span: 24 }} lg={{ span: 8, offset: 8 }}>
                    <AddAsset />
                </Col>
            </Row>
        </div>
    );
};

export default AddAssetPage;

import React from "react";
import { Row, Col } from "antd";
import Assets from "Components/ListView/Assets";

const Homepage = () => {
    return (
        <div>
            <Row>
                <Col xs={{ span: 24 }} lg={{ span: 8, offset: 8 }}>
                    <Assets />
                </Col>
            </Row>
        </div>
    );
};

export default Homepage;

export const Colors = [
    {
        from: "#1E4FD7",
        to: "#2948ff",
    },
    {
        from: "#f85032",
        to: "#e73827",
    },
    {
        from: "#0f2027",
        to: "#2c5364",
    },
    {
        from: "#B24592",
        to: "#F15F79",
    },
    {
        from: "#41295a",
        to: "#2F0743",
    },
    {
        from: "#CB356B",
        to: "#BD3F32",
    },
    {
        from: "#bdc3c7",
        to: "#2c3e50",
    },
    {
        from: "#373b44",
        to: "#4286f4",
    },
    {
        from: "#FF416C",
        to: "#FF4B2B",
    },
    {
        from: "#bc4e9c",
        to: "#f80759",
    },
];

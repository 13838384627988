import React from "react";
import { Row, Col } from "antd";
import AssetManager from "Components/Drawers/AssetManager";

const AssetViewer = () => {
    return (
        <div>
            <Row>
                <Col
                    xs={{ span: 24 }}
                    lg={{ span: 8, offset: 8 }}
                    style={{ padding: "1rem 2rem", minHeight: "100vh" }}
                >
                    <AssetManager />
                </Col>
            </Row>
        </div>
    );
};

export default AssetViewer;

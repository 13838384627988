import React, { useEffect, useState } from "react";
import {
    Button,
    Drawer,
    Col,
    Row,
    Space,
    List,
    Result,
    Typography,
    Skeleton,
    notification,
} from "antd";
import PortfolioOnBuy from "Components/Forms/PortfolioOnBuy";
import PortfolioOnSell from "Components/Forms/PortfolioOnSell";
import { useSelector } from "react-redux";
import TransactionListView from "Components/ListView/Transaction";
import ViewPortfolio from "Components/Dashboard/ViewPortfolio";
import { getFormattedAmount } from "Shared/Utils";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { getCoinInfo } from "utils/CoinGecko";
import {
    mpTapBuyCTA,
    mpTapGoBack,
    mpTapSellCTA,
    mpViewMarketData,
} from "Shared/Mixpanel/AssetsManager";
import Transaction from "./Transaction";
import MarketView from "./MarketView";

const { Title, Text } = Typography;

const AssetManager = () => {
    const history = useHistory();
    const { search } = useLocation();
    const intent = new URLSearchParams(search).get("intent");
    const selectedPortfolio = useSelector(
        (state) => state.portfolios.selectedPortfolio
    );
    document.title = `Vewing ${selectedPortfolio.name} - Portfolio App`;
    const asset = useSelector((state) => state.assets.selectedAsset);
    const [showBuyDrawer, setShowBuyDrawer] = useState(intent === "buy");
    const [showSellDrawer, setShowSellDrawer] = useState(intent === "sell");
    const [showMarket, setShowMarket] = useState(intent === "market");
    const [coinInfo, setCoinInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    const getCoin = async () => {
        const response = await getCoinInfo([asset]);
        setCoinInfo(response[0]);
        if (!response) {
            notification.error({
                message: `Error fetching ${asset.name}`,
                description: `APIs are busy, please try in a while.`,
            });
        }
        setLoading(false);
    };
    useEffect(() => {
        if (asset) getCoin();
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            {coinInfo && asset ? (
                <div>
                    <Drawer
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <Title
                                        level={4}
                                        style={{
                                            color: "#fff",
                                            marginBottom: 0,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {/* <img
                                            src={coinInfo.image}
                                            alt="coin icon"
                                            width="20px"
                                        />
                                        &nbsp; */}
                                        {asset.name}
                                    </Title>
                                </div>
                                <div>
                                    <Text style={{ color: "#fff" }}>
                                        &#8377;&nbsp;
                                        {getFormattedAmount(
                                            coinInfo.current_price
                                        )}
                                    </Text>
                                </div>
                            </div>
                        }
                        placement="right"
                        closable={false}
                        height="100%"
                        width="100%"
                        open
                        footer={
                            <Row>
                                <Col
                                    sm={24}
                                    md={{ span: 8, offset: 8 }}
                                    style={{
                                        width: "-webkit-fill-available",
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: "center",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Button
                                            style={{
                                                marginRight: 8,
                                                fontSize: "12px",
                                            }}
                                            type="ghost"
                                            onClick={() => {
                                                mpTapGoBack({
                                                    screen: "Asset Manager",
                                                });
                                                history.push(
                                                    `/portfolios/${selectedPortfolio.name}`
                                                );
                                            }}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            style={{
                                                marginRight: 8,
                                                padding: 4,
                                                width: "-webkit-fill-available",
                                                background: "#52c41a",
                                                color: "#fff",
                                            }}
                                            onClick={() => {
                                                mpTapBuyCTA({
                                                    type: "primary",
                                                });
                                                setShowBuyDrawer(true);
                                            }}
                                        >
                                            {asset.transactions?.length > 0
                                                ? "Add "
                                                : "Buy "}
                                        </Button>
                                        <Button
                                            style={{
                                                marginRight: 8,
                                                padding: 4,
                                                width: "-webkit-fill-available",
                                                background: "#f5222d",
                                                color: "#fff",
                                                display:
                                                    asset.transactions?.length >
                                                    0
                                                        ? ""
                                                        : "none",
                                            }}
                                            onClick={() => {
                                                mpTapSellCTA();
                                                setShowSellDrawer(true);
                                            }}
                                        >
                                            Sell
                                        </Button>
                                        <Button
                                            type="primary"
                                            style={{
                                                padding: 4,
                                                width: "-webkit-fill-available",
                                            }}
                                            onClick={() => {
                                                mpViewMarketData({
                                                    coin: asset.name,
                                                });
                                                setShowMarket(true);
                                            }}
                                        >
                                            Market
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        }
                    >
                        <Row>
                            <Col
                                sm={24}
                                md={{ span: 8, offset: 8 }}
                                style={{
                                    width: "-webkit-fill-available",
                                }}
                            >
                                {!isEmpty(asset.portfolio) && (
                                    <ViewPortfolio coinInfo={coinInfo} />
                                )}
                                <Space
                                    direction="vertical"
                                    size={2}
                                    style={{
                                        textAlign: "left",
                                        width: "-webkit-fill-available",
                                    }}
                                >
                                    {asset?.transactions?.length > 0 ? (
                                        <div>
                                            <h2>Recent Transactions</h2>
                                            {asset.transactions
                                                .map((item) => {
                                                    return (
                                                        <List.Item
                                                            key={item.uid}
                                                            style={{
                                                                // prettier-ignore
                                                                width:
                                                                    "-webkit-fill-available",
                                                            }}
                                                        >
                                                            <TransactionListView
                                                                item={item}
                                                                key={item.uid}
                                                            />
                                                        </List.Item>
                                                    );
                                                })
                                                .reverse()}
                                        </div>
                                    ) : (
                                        <Result
                                            status="500"
                                            // title="500"
                                            subTitle={
                                                "You haven't made any transaction yet."
                                            }
                                            extra={
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        mpTapGoBack({
                                                            screen: "Asset Manager",
                                                        });
                                                        setShowBuyDrawer(true);
                                                    }}
                                                >
                                                    Buy{" "}
                                                    {asset.name.length > 10
                                                        ? `${asset.name.slice(
                                                              0,
                                                              10
                                                          )}...`
                                                        : asset.name}
                                                </Button>
                                            }
                                        />
                                    )}
                                </Space>
                            </Col>
                        </Row>
                    </Drawer>
                    <Transaction
                        title={`Buy ${asset.name}`}
                        visible={showBuyDrawer}
                        onClose={() => setShowBuyDrawer(false)}
                        backCTAAction={() => setShowBuyDrawer(false)}
                        component={<PortfolioOnBuy coinInfo={coinInfo} />}
                    />
                    <Transaction
                        title={`Sell ${asset.name}`}
                        visible={showSellDrawer}
                        onClose={() => setShowSellDrawer(false)}
                        backCTAAction={() => setShowSellDrawer(false)}
                        component={<PortfolioOnSell coinInfo={coinInfo} />}
                    />
                    <MarketView
                        setShowMarket={setShowMarket}
                        showMarket={showMarket}
                        coinInfo={coinInfo}
                    />
                </div>
            ) : (
                <Drawer
                    title={
                        <Title
                            level={4}
                            style={{
                                color: "#fff",
                                marginBottom: 0,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {`Loading ${asset.name || ""}...`}
                        </Title>
                    }
                    placement="right"
                    closable={false}
                    height="100%"
                    width="100%"
                    open
                >
                    <Row>
                        <Col md={{ span: 8, offset: 8 }}>
                            {[1, 2, 3, 4].map((index) => (
                                <Skeleton
                                    loading={loading}
                                    title
                                    paragraph
                                    active
                                    avatar
                                    key={index}
                                />
                            ))}
                        </Col>
                    </Row>
                </Drawer>
            )}
        </div>
    );
};

export default AssetManager;

export const app = `${
    process.env.NODE_ENV === "development"
        ? "http://localhost:8065"
        : process.env.PUBLIC_URL
}`;
export const authApp = `${
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_LOCAL_AUTH_API
        : process.env.REACT_APP_PROD_AUTH_API
}`;

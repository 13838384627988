import actionTypes from "./types";

export const add = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.ADD_ASSET,
        payload,
    });
};

export const setPortfolioAssets = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.SET_PORTFOLIO_ASSETS,
        payload,
    });
};

export const setSelectedAsset = (asset) => (dispatch) => {
    return dispatch({
        type: actionTypes.SET_SELECTED_ASSET,
        payload: asset,
    });
};

export const addTransaction = (transaction, portfolio) => (dispatch) => {
    return dispatch({
        type: actionTypes.ADD_TRANSACTION,
        payload: {
            transaction,
            portfolio,
        },
    });
};

export const holdMyResult = (portfolio) => (dispatch) => {
    return dispatch({
        type: actionTypes.HOLD_MY_RESULT,
        payload: portfolio,
    });
};

export const buy = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.BUY,
        payload,
    });
};

export const sell = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.SELL,
        payload,
    });
};

export const updateBackedupAssets = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.UPDATE_BACKEDUP_ASSETS,
        payload,
    });
};

import { notification } from "antd";
import Axios from "axios";
import Cookies from "js-cookie";

export const getCoinInfo = async (assets) => {
    try {
        const ids = assets.map((asset) => asset.id).join(",");
        const currency = "inr";
        const api = `${process.env.REACT_APP_API}/coin-gecko/market-data?ids=${ids}&currency=${currency}`;
        const response = await Axios.get(api, {
            headers: {
                Authorization: `Bearer ${Cookies.get("me_apps_access_token")}`,
            },
        });
        return response.data;
    } catch (e) {
        notification.error({
            message: `Failed to fetch market data, try again in sometime`,
        });
        return [];
    }
};

export const getCoinsList = async () => {
    try {
        const api = `${process.env.REACT_APP_API}/coin-gecko/list-coins`;
        const response = await Axios.get(api, {
            headers: {
                Authorization: `Bearer ${Cookies.get("me_apps_access_token")}`,
            },
        });
        return response.data;
    } catch (e) {
        notification.error({
            message: `Failed to fetch all coins from server, try after sometime`,
        });
        return [];
    }
};

import React from "react";
import { Button, Drawer, Tag, Col, Row, Space, Typography } from "antd";
import { getFormattedAmount } from "Shared/Utils";
import { PerformanceTag } from "Shared/PerformanceTag";

const { Title, Text } = Typography;

const MarketView = (props) => {
    const {
        coinInfo = {},
        showMarket = false,
        setShowMarket = () => {},
    } = props;

    const {
        price_change_24h,
        price_change_percentage_24h,
        high_24h,
        low_24h,
        ath,
        atl,
        ath_change_percentage,
        atl_change_percentage,
        total_volume,
        market_cap,
        circulating_supply,
        market_cap_rank,
        symbol,
    } = coinInfo;

    return (
        <div>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            <Title
                                level={4}
                                style={{
                                    marginBottom: 0,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {symbol.toUpperCase()} Statistics
                            </Title>
                        </div>
                        <div>
                            <Text>#{market_cap_rank}</Text>
                        </div>
                    </div>
                }
                placement="bottom"
                closable={false}
                height="80%"
                width="100%"
                open={showMarket}
                headerStyle={{
                    padding: "1rem 2rem 1rem",
                }}
                mask
                maskClosable
                bodyStyle={{ paddingBottom: 0, paddingTop: "12px" }}
                footer={
                    <div
                        style={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button
                            style={{
                                fontSize: "12px",
                            }}
                            type="ghost"
                            onClick={() => setShowMarket(false)}
                        >
                            Back
                        </Button>
                    </div>
                }
            >
                <Space
                    direction="vertical"
                    size={2}
                    style={{
                        textAlign: "left",
                        width: "-webkit-fill-available",
                    }}
                >
                    <Row>
                        <Col sm={24} md={{ span: 8, offset: 8 }}>
                            {/* Categories */}
                            <Row
                                style={{
                                    padding: "0.5rem 0",
                                }}
                            >
                                {[].map((category) => (
                                    <Tag
                                        key={category}
                                        color="purple"
                                        style={{ margin: "0.2rem" }}
                                    >
                                        {category}
                                    </Tag>
                                ))}
                            </Row>

                            {/* Stats */}
                            <Row>
                                {/* price change */}
                                <Col
                                    style={{
                                        width: "-webkit-fill-available",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: "1px solid #d9d9d9",
                                        padding: "8px 0",
                                    }}
                                >
                                    <div>
                                        Price Change <Tag>24h</Tag>
                                    </div>
                                    <div>
                                        &#8377;&nbsp;
                                        {getFormattedAmount(price_change_24h)}
                                        {PerformanceTag(
                                            price_change_percentage_24h,
                                            true
                                        )}
                                    </div>
                                </Col>
                                {/* low 24hr */}
                                <Col
                                    style={{
                                        width: "-webkit-fill-available",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: "1px solid #d9d9d9",
                                        padding: "8px 0",
                                    }}
                                >
                                    <div>
                                        Low <Tag>24h</Tag>
                                    </div>
                                    <div>
                                        &#8377;&nbsp;
                                        {getFormattedAmount(low_24h)}
                                    </div>
                                </Col>
                                {/* high 24hr */}
                                <Col
                                    style={{
                                        width: "-webkit-fill-available",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: "1px solid #d9d9d9",
                                        padding: "8px 0",
                                    }}
                                >
                                    <div>
                                        High <Tag>24h</Tag>
                                    </div>
                                    <div>
                                        &#8377;&nbsp;
                                        {getFormattedAmount(high_24h)}
                                    </div>
                                </Col>
                                {/* All time high */}
                                <Col
                                    style={{
                                        width: "-webkit-fill-available",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: "1px solid #d9d9d9",
                                        padding: "8px 0",
                                    }}
                                >
                                    <div>All time high</div>
                                    <div>
                                        &#8377;&nbsp;
                                        {getFormattedAmount(ath)}&nbsp;
                                        {PerformanceTag(
                                            ath_change_percentage,
                                            true
                                        )}
                                    </div>
                                </Col>
                                {/* All time low */}
                                <Col
                                    style={{
                                        width: "-webkit-fill-available",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: "1px solid #d9d9d9",
                                        padding: "8px 0",
                                    }}
                                >
                                    <div>All time low</div>
                                    <div>
                                        &#8377;&nbsp;
                                        {getFormattedAmount(atl)}
                                        {PerformanceTag(
                                            atl_change_percentage,
                                            true
                                        )}
                                    </div>
                                </Col>
                                {/* total volume */}
                                <Col
                                    style={{
                                        width: "-webkit-fill-available",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: "1px solid #d9d9d9",
                                        padding: "8px 0",
                                    }}
                                >
                                    <div>Total Volume</div>
                                    <div>
                                        &#8377;&nbsp;
                                        {getFormattedAmount(total_volume)}
                                    </div>
                                </Col>
                                {/* market cap */}
                                <Col
                                    style={{
                                        width: "-webkit-fill-available",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: "1px solid #d9d9d9",
                                        padding: "8px 0",
                                    }}
                                >
                                    <div>Market Cap</div>
                                    <div>
                                        &#8377;&nbsp;
                                        {getFormattedAmount(market_cap)}
                                    </div>
                                </Col>
                                {/* circulating supply */}
                                <Col
                                    style={{
                                        width: "-webkit-fill-available",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: "1px solid #d9d9d9",
                                        padding: "8px 0",
                                    }}
                                >
                                    <div>Circulating Supply</div>
                                    <div>
                                        {getFormattedAmount(circulating_supply)}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Space>
            </Drawer>
        </div>
    );
};

export default MarketView;

import produce from "immer";
import actionTypes from "../Actions/types";

const initialState = {
    userInfo: null,
};

const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.UPDATE_USER:
                draft.userInfo = action.payload;
                break;

            default:
                return draft;
        }
    });
export default reducer;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Cookies from "js-cookie";
import Axios from "axios";
import {
    Button,
    Drawer,
    Skeleton,
    Result,
    Row,
    Col,
    Typography,
    notification,
} from "antd";
import Loader from "Shared/Loader";
import AssetInWallet from "Components/Dashboard/AssetInWallet";
import { getFormattedAmount, getFormattedPercentage } from "Shared/Utils";
import getCurrencySymbol from "Shared/CurrancySymbol";
import AddBinance from "Components/Forms/AddBinance";
import { SettingOutlined } from "@ant-design/icons";
import BinancePreferences from "Components/Drawers/BinancePreferences";

const BinancePortfolio = () => {
    document.title = `Binance - Portfolio App`;
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [overview, setOverview] = useState(0);
    const [investment, setInvestment] = useState(0);
    const [usdt, setUsdt] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showAICTA, setShowAICTA] = useState(false);
    const [hasConnectedBinance, setHasConnectedBinance] = useState(true);
    const [showConnectBinanceDrawer, setShowConnectBinanceDrawer] =
        useState(false);
    const [sortBy, setSortBy] = useState("currentValue");
    const [showOptionsDrawer, setShowOptionsDrawer] = useState(false);

    const onClose = () => {
        history.push("/");
    };

    const sortAssets = (coins) => {
        if (sortBy === "investment")
            coins.sort(
                (a, b) =>
                    b.history.investmentInUSDT - a.history.investmentInUSDT
            );
        else if (sortBy === "currentValue")
            coins.sort((a, b) => b.net.usd - a.net.usd);
        else if (sortBy === "name")
            coins.sort((a, b) => a.name.localeCompare(b.name));
        else if (sortBy === "roi")
            coins.sort((a, b) => {
                const ratioA = a.net.usd / a.history.investmentInUSDT;
                const ratioB = b.net.usd / b.history.investmentInUSDT;

                // Check for infinity
                if (!Number.isFinite(ratioA) && !Number.isFinite(ratioB)) {
                    return 0; // Both are infinity, no change in order
                }
                if (!Number.isFinite(ratioA)) {
                    return 0; // A is infinity, move it to the end
                }
                if (!Number.isFinite(ratioB)) {
                    return 0; // B is infinity, move it to the end
                }

                // Check for NaN
                if (Number.isNaN(ratioA) && Number.isNaN(ratioB)) {
                    return 0; // Both are NaN, no change in order
                }
                if (Number.isNaN(ratioA)) {
                    return 0; // A is NaN, move it to the end
                }
                if (Number.isNaN(ratioB)) {
                    return 0; // B is NaN, move it to the end
                }

                // Normal comparison if both values are finite and not NaN
                return ratioB - ratioA;
            });
        else if (sortBy === "price")
            coins.sort((a, b) => b.currentPrice - a.currentPrice);
        else if (sortBy === "quantity")
            coins.sort((a, b) => b.net.value - a.net.value);

        setItems([...coins]);
    };

    const getAssetInfo = async () => {
        setOverview(0);
        setInvestment(0);
        setLoading(true);
        try {
            const api = `${process.env.REACT_APP_API}/binance/balances`;
            const response = await Axios.get(api, {
                headers: {
                    Authorization: `Bearer ${Cookies.get(
                        "me_apps_access_token"
                    )}`,
                },
            });
            const { wallet } = response.data;
            const assets = [];
            // eslint-disable-next-line guard-for-in, no-restricted-syntax
            for (const asset in wallet) {
                if (asset !== "USDT") {
                    assets.push({
                        name: asset,
                        currentValue: wallet[asset].net.usd,
                        ...wallet[asset],
                    });
                    setInvestment(
                        (prev) => prev + wallet[asset].history.investmentInUSDT
                    );
                    setOverview((prev) => prev + wallet[asset].net.usd);
                } else {
                    setUsdt(wallet[asset].net.usd);
                }
            }
            setItems(assets);
            sortAssets(assets);
            setShowAICTA(true);
        } catch (error) {
            if (error.response.status === 403) {
                setShowConnectBinanceDrawer(true);
                setHasConnectedBinance(false);
                notification.error({
                    message: `You have not connected your wallet.`,
                    description: `Please connect your wallet to view your assets.`,
                    key: "wallet-not-connected",
                });
            } else {
                notification.error({
                    message: error.response.data.message,
                    description: `We have informed the developers. Please try again later.`,
                    key: "binance-api-failed",
                });
            }
        }
        setLoading(false);
    };

    // const handleNewsClick = ()s=> history.push("/imported/binance/ai-news");

    useEffect(() => {
        if (hasConnectedBinance) getAssetInfo();
    }, []);

    useEffect(() => {
        if (hasConnectedBinance) sortAssets(items);
    }, [sortBy]);

    return (
        <div>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "baseline",
                            }}
                        >
                            <div style={{ fontSize: "14px", color: "#fff" }}>
                                Your coins in&nbsp;
                            </div>
                            <Typography.Title
                                level={4}
                                style={{
                                    color: "#fff",
                                    marginBottom: "0 !important",
                                    fontWeight: 600,
                                }}
                            >
                                Binance
                            </Typography.Title>
                        </div>
                        <div>
                            <SettingOutlined
                                onClick={() => setShowOptionsDrawer(true)}
                                style={{
                                    fontSize: "18px",
                                    padding: "8px",
                                    border: "1px solid #fff",
                                    borderRadius: "4px",
                                }}
                            />
                        </div>
                    </div>
                }
                placement="right"
                closable={false}
                height="100%"
                width="100%"
                open
                headerStyle={{
                    zIndex: "2",
                    margin: "auto 0",
                }}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <Row>
                        <Col
                            sm={24}
                            lg={{ span: 8, offset: 8 }}
                            style={{ width: "-webkit-fill-available" }}
                        >
                            <div
                                style={{
                                    textAlign: "center",
                                    alignItems: "center",
                                    display: `${loading ? "none" : "flex"}`,
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                        fontSize: "12px",
                                    }}
                                    type="ghost"
                                    onClick={onClose}
                                >
                                    Back
                                </Button>

                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "baseline",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                paddingRight: "0.5rem",
                                            }}
                                        >
                                            <small>Current</small>
                                            <span>
                                                <Typography.Title
                                                    level={5}
                                                    style={{
                                                        color: "#fff",
                                                        marginBottom:
                                                            "0 !important",
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    {getCurrencySymbol("USD")}
                                                    {getFormattedAmount(
                                                        overview,
                                                        false,
                                                        1
                                                    )}
                                                </Typography.Title>
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                padding: "0 0.5rem",
                                            }}
                                        >
                                            <small>Portfolio</small>
                                            <span>
                                                <Typography.Title
                                                    level={5}
                                                    style={{
                                                        marginBottom:
                                                            "0 !important",
                                                        color:
                                                            overview >
                                                            investment
                                                                ? "#52c41a"
                                                                : "#f5222d",
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    {getFormattedPercentage(
                                                        ((overview -
                                                            investment) /
                                                            investment) *
                                                            100,
                                                        1
                                                    )}
                                                </Typography.Title>
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                paddingRight: "0.5rem",
                                                paddingLeft: "0.5rem",
                                            }}
                                        >
                                            <small>Balance</small>
                                            <span>
                                                <Typography.Title
                                                    level={5}
                                                    style={{
                                                        color: "#fff",
                                                        marginBottom:
                                                            "0 !important",
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    {getCurrencySymbol("USD")}
                                                    {getFormattedAmount(
                                                        usdt,
                                                        true
                                                    )}
                                                </Typography.Title>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <Loader
                                    handleClick={() => {
                                        getAssetInfo();
                                    }}
                                    loading={loading}
                                />
                            </div>
                        </Col>
                    </Row>
                }
            >
                <Row>
                    <Col
                        sm={24}
                        lg={{ span: 8, offset: 8 }}
                        style={{ width: "-webkit-fill-available" }}
                    >
                        {[1, 2, 3, 4, 5].map((index) => (
                            <Skeleton
                                loading={loading}
                                title
                                paragraph
                                active
                                avatar
                                key={index}
                            />
                        ))}
                        {!loading && (
                            <AddBinance
                                onClose={() =>
                                    setShowConnectBinanceDrawer(false)
                                }
                                showConnectBinanceDrawer={
                                    showConnectBinanceDrawer
                                }
                                getAssetInfo={getAssetInfo}
                            />
                        )}
                        {!loading && items.length > 0 && (
                            <React.Fragment>
                                {items.map((asset, index) => (
                                    <div
                                        style={{
                                            marginBottom: "0.5rem",
                                        }}
                                    >
                                        <AssetInWallet
                                            asset={asset}
                                            index={index}
                                            total={overview}
                                            investment={investment}
                                            sortBy={sortBy}
                                        />
                                    </div>
                                ))}
                            </React.Fragment>
                        )}
                        {!loading && items.length === 0 && showAICTA && (
                            <Result
                                status="500"
                                title="Your coins come in here"
                                subTitle="You haven't baught any coins yet."
                            />
                        )}
                        {!loading && items.length === 0 && !showAICTA && (
                            <Result
                                status="404"
                                title="We failed to get your assets from Binance"
                                subTitle="When the traffic is high on binance, their API fails to respond. Please try again later"
                            />
                        )}
                    </Col>
                </Row>
            </Drawer>
            <BinancePreferences
                onClose={() => setShowOptionsDrawer(false)}
                open={showOptionsDrawer}
                setSortBy={setSortBy}
                sortBy={sortBy}
            />
        </div>
    );
};

export default BinancePortfolio;

import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, Row, Space, Typography } from "antd";
import ChangeView from "Shared/Statistics";
import * as assetAction from "store/Actions/asset";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import { useHistory, useParams } from "react-router-dom";
import { getCoinInfo } from "utils/CoinGecko";
import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";
import { performBackup } from "utils/Firebase/backup";
import { updateLastBackup } from "store/Actions/settings";
import {
    mpTapAddTransaction,
    mpTapGoBack,
} from "Shared/Mixpanel/AssetsManager";

const Result = () => {
    const dispatch = useDispatch();
    const { type } = useParams();
    const history = useHistory();
    const selectedPortfolio = useSelector(
        (state) => state.portfolios.selectedPortfolio
    );
    const asset = useSelector((state) => state.assets.selectedAsset);
    const portfolio = useSelector((state) => state.assets.holdMyResult);

    // for backup
    const portfolios = useSelector((state) => state.portfolios.portfolios);
    const assets = useSelector((state) => state.assets.assets);
    const userInfo = useSelector((state) => state.users.userInfo);
    const settings = useSelector((state) => state.settings);

    const orientation = "left";
    const [loading, setLoading] = useState(true);
    const [coinInfo, setCoinInfo] = useState({});
    const handleAddTransaction = async () => {
        const transaction = {
            createdAt: new Date(),
            portfolioSnapshot: portfolio,
            type,
            uid: nanoid(5),
        };
        dispatch(assetAction.addTransaction(transaction, portfolio));
        if (userInfo) {
            dispatch(updateLastBackup(Date.now()));
            await performBackup(
                "users",
                userInfo,
                portfolios,
                assets,
                settings
            );
        }
        mpTapAddTransaction({ type });
        history.push(
            `/portfolios/${selectedPortfolio.name}/assets/${asset.id}`
        );
    };

    const onClose = () => {
        history.push(
            `/portfolios/${selectedPortfolio.name}/assets/${asset.id}`
        );
    };

    const getCoin = async () => {
        setLoading(true);
        setCoinInfo((await getCoinInfo([asset]))[0]);
        setLoading(false);
    };

    useEffect(() => {
        if (asset) getCoin();
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Drawer
                title={
                    <div
                        style={{
                            zIndex: "2",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "baseline",
                        }}
                    >
                        <div style={{ fontSize: "14px", color: "#fff" }}>
                            You are {type}ing&nbsp;
                        </div>
                        <Typography.Title
                            level={4}
                            style={{
                                color: "#fff",
                                marginBottom: "0 !important",
                                fontWeight: 600,
                            }}
                        >
                            {asset.name}
                        </Typography.Title>
                    </div>
                }
                placement="right"
                closable={false}
                width="100%"
                onClose={() => onClose()}
                open
                footer={
                    <Row>
                        <Col
                            sm={24}
                            lg={{ span: 8, offset: 8 }}
                            style={{ width: "-webkit-fill-available" }}
                        >
                            {!_.isEmpty(coinInfo) && (
                                <div
                                    style={{
                                        textAlign: "right",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Button
                                        style={{ marginRight: 8 }}
                                        onClick={() => {
                                            mpTapGoBack({
                                                screen: "RESULTS_SCREEN",
                                            });
                                            onClose();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="primary"
                                        style={{
                                            width: "-webkit-fill-available",
                                        }}
                                        onClick={handleAddTransaction}
                                        // disabled={true}
                                    >
                                        Add Transaction
                                    </Button>
                                </div>
                            )}
                        </Col>
                    </Row>
                }
            >
                <Row>
                    <Col
                        sm={24}
                        lg={{ span: 8, offset: 8 }}
                        style={{ width: "-webkit-fill-available" }}
                    >
                        <Space
                            direction="vertical"
                            size={2}
                            style={{
                                textAlign: "left",
                                width: "-webkit-fill-available",
                            }}
                        >
                            {!loading && !_.isEmpty(coinInfo) ? (
                                <ChangeView
                                    title={
                                        <Divider orientation={orientation}>
                                            Overall Value
                                        </Divider>
                                    }
                                    previousValue={
                                        portfolio.previous.numberOfCoins *
                                        coinInfo.current_price
                                    }
                                    latestValue={
                                        portfolio.latest.numberOfCoins *
                                        coinInfo.current_price
                                    }
                                />
                            ) : (
                                <span>
                                    Calculating <LoadingOutlined />
                                </span>
                            )}
                            <ChangeView
                                title={
                                    <Divider orientation={orientation}>
                                        Coins
                                    </Divider>
                                }
                                previousValue={portfolio.previous.numberOfCoins}
                                latestValue={portfolio.latest.numberOfCoins}
                            />
                            <ChangeView
                                title={
                                    <Divider orientation={orientation}>
                                        Price per coin
                                    </Divider>
                                }
                                previousValue={portfolio.previous.marketPrice}
                                latestValue={portfolio.latest.marketPrice}
                                isReverseSentiment
                            />
                            <ChangeView
                                title={
                                    <Divider orientation={orientation}>
                                        {type === "buy"
                                            ? "Your Investments"
                                            : "Your Profit/Loss"}
                                    </Divider>
                                }
                                previousValue={
                                    type === "buy"
                                        ? portfolio.previous.investment
                                        : portfolio.latest?.sellInfo
                                              ?.intialCapitalForPurchase
                                }
                                latestValue={
                                    type === "buy"
                                        ? portfolio.latest.investment
                                        : portfolio.latest?.sellInfo
                                              ?.amountToSell
                                }
                                isSell
                            />
                        </Space>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
};

export default Result;

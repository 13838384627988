import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const Disclaimer = () => {
    return (
        <div>
            <Text type="secondary">
                All your data is stored on your device, we do not collect any
                data on you. Although, if you wish to sync data across devices
                you can login with Google.
            </Text>
        </div>
    );
};

export default Disclaimer;

import React from "react";
import { Button, Col, Drawer, Row } from "antd";
import { mpTapGoBack } from "Shared/Mixpanel/AssetsManager";

const Transaction = (props) => {
    const {
        title = "",
        visible = false,
        onClose = () => {},
        backCTAAction = () => {},
        component = <div />,
    } = props;

    return (
        <div>
            <Drawer
                title={<h4 style={{ fontWeight: "400" }}>{title}</h4>}
                placement="right"
                closable={false}
                width="100%"
                height="100%"
                onClose={onClose}
                open={visible}
                footer={
                    <div>
                        <Button
                            onClick={() => {
                                backCTAAction();
                                mpTapGoBack({ screen: "BUY_SELL_FORM" });
                            }}
                            style={{ marginRight: 8 }}
                        >
                            Back
                        </Button>
                    </div>
                }
            >
                <Row>
                    <Col
                        sm={24}
                        lg={{ span: 8, offset: 8 }}
                        style={{ width: "-webkit-fill-available" }}
                    >
                        {component}
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
};

export default Transaction;

import React from "react";
import { Statistic } from "antd";
import { getFormattedAmount } from "Shared/Utils";

const EmotionlessStats = (props) => {
    const { title = "", value = "" } = props;
    return (
        <div style={{ width: "50%" }}>
            <Statistic title={title} value={getFormattedAmount(value)} />
        </div>
    );
};

export default EmotionlessStats;

import actionTypes from "./types";

export const updateTheme = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.UPDATE_THEME,
        payload,
    });
};

export const updateSettings = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.UPDATE_SETTINGS,
        payload,
    });
};

export const updateLastBackup = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.UPDATE_LAST_BACKUP,
        payload,
    });
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
    Avatar,
    Button,
    Col,
    Divider,
    Drawer,
    Result,
    Row,
    Skeleton,
    Space,
    Spin,
    Tooltip,
} from "antd";
import Title from "antd/lib/typography/Title";
import AddPortfolio from "Components/Forms/AddPortfolio";
import OverviewCard from "Components/Dashboard/OverviewCard";
import { getCoinInfo } from "utils/CoinGecko";
import Cookies from "js-cookie";
import * as dayjs from "dayjs";
import * as duration from "dayjs/plugin/duration";
import {
    DownOutlined,
    LoadingOutlined,
    SettingFilled,
    SettingOutlined,
    UpOutlined,
} from "@ant-design/icons";
import InvestmentList from "Components/Drawers/InvestmentList";
import { getFormattedAmount } from "Shared/Utils";
import { orderBy } from "lodash";
import Settings from "Components/Drawers/Settings";
import { fetchBackup } from "utils/Firebase/backup";
import {
    setSelectedPortfolio,
    updateBackedupPortfolios,
} from "store/Actions/portfolio";
import { updateSettings } from "store/Actions/settings";
import { updateBackedupAssets } from "store/Actions/asset";
import { updateUser } from "store/Actions/users";
import {
    mpTapCreatePortfolioCTA,
    mpTapInvestmentListViewCTA,
    mpTapRefreshCTA,
    mpTapSettingsCTA,
    mpTapViewMoreCTA,
} from "Shared/Mixpanel/Portfolios";
import Loader from "Shared/Loader";
import AutoInvest from "Components/Forms/AutoInvest";
import ImportedPortfolioCard from "Components/Dashboard/ImportedPortfolioCard";
// import Services from "./Services";

const Portfolios = () => {
    dayjs.extend(duration);
    const history = useHistory();
    const { search } = useLocation();
    const login = new URLSearchParams(search).get("login");
    const dispatch = useDispatch();
    const portfolios = useSelector((state) => state.portfolios.portfolios);
    const assets = useSelector((state) => state.assets.assets);

    const [showAddPortfolioDrawer, setShowAddPortfolioDrawer] = useState(false);
    const [items, setItems] = useState({
        newPortfolios: [],
        activePortfolios: [],
        inactivePortfolios: [],
    });
    const [headerStats, setHeaderStats] = useState({
        currentValue: 0,
        totalInvestment: 0,
        pl: 0,
    });
    const [refreshing, setRefreshing] = useState(false);
    const [showInvestmentsDrawer, setShowInvestmentsDrawer] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [investments, setInvestments] = useState([]);
    const [userInfo, setUserInfo] = useState(
        useSelector((state) => state.users.userInfo)
    );
    const [showAutoInvestDrawer, setShowAutoInvestDrawer] = useState(false);
    const [showImportedPortfolios, setShowImportedPortfolios] = useState(false);

    const handleSettingsDrawer = () => {
        setShowSettings(!showSettings);
    };

    const handleMoreClk = (uid) => {
        const portfolio = portfolios.find((item) => item.uid === uid);
        mpTapViewMoreCTA({
            name: portfolio.name,
        });
        dispatch(setSelectedPortfolio(portfolio));
        history.push(`/portfolios/${portfolio.name}`);
    };

    const handleBinanceDrawer = () => {
        history.push(`/imported/binance`);
    };

    const getAllPortfolioAssetsInfo = async () => {
        setRefreshing(true);
        const _headerStats = {
            currentValue: 0,
            totalInvestment: 0,
            pl: 0,
        };
        const coinsMarketData = await getCoinInfo(assets);
        const _portfolios = await portfolios.map(async (portfolio) => {
            let currentValue = 0;
            let totalInvestment = 0;
            let pl = 0;
            const currentPortfolioAssets = assets.filter(
                (asset) => asset.portfolioId === portfolio.uid
            );
            const allCoins = await currentPortfolioAssets.map((asset) => {
                if (asset.transactions.length > 0) {
                    const coinInfo = coinsMarketData.find(
                        (data) => data.id === asset.id
                    );
                    return {
                        ...asset,
                        coinInfo,
                    };
                }
                return asset;
            });
            const coins = allCoins.filter((coin) => coin.coinInfo);
            coins.forEach((coin) => {
                currentValue +=
                    coin.transactions.length > 0
                        ? coin.coinInfo.current_price *
                          coin.portfolio.latest.numberOfCoins
                        : 0;
                totalInvestment +=
                    coin.transactions.length > 0
                        ? coin.portfolio.latest.investment
                        : 0;
            });
            _headerStats.currentValue += currentValue;
            _headerStats.totalInvestment += totalInvestment;
            pl =
                totalInvestment !== 0
                    ? ((currentValue - totalInvestment) / totalInvestment) * 100
                    : 0;
            return {
                ...portfolio,
                totalInvestment,
                currentValue,
                pl,
            };
        });
        const portfoliosData = await Promise.all(_portfolios);
        const activePortfolios = orderBy(
            portfoliosData.filter((portfolio) => portfolio.totalInvestment > 0),
            ["pl"],
            ["desc"]
        );
        const inactivePortfolios = orderBy(
            portfoliosData.filter(
                (portfolio) =>
                    (portfolio.createdAt
                        ? dayjs(Date.now()).diff(
                              portfolio.createdAt,
                              "days",
                              true
                          ) >= 1
                        : true) && portfolio.totalInvestment === 0
            ),
            ["createdAt"],
            "desc"
        );
        const newPortfolios = orderBy(
            portfoliosData.filter(
                (portfolio) =>
                    portfolio.createdAt &&
                    dayjs(Date.now()).diff(portfolio.createdAt, "days", true) <
                        1 &&
                    portfolio.totalInvestment === 0
            ),
            ["createdAt"],
            ["desc"]
        );
        setItems({
            newPortfolios,
            activePortfolios,
            inactivePortfolios,
        });
        setHeaderStats(_headerStats);
        setRefreshing(false);
    };

    const getTotalInvestmentList = async () => {
        const list = await portfolios.map((portfolio) => {
            const currentPortfolioAssets = assets.filter(
                (asset) => asset.portfolioId === portfolio.uid
            );
            const updatedAssets = currentPortfolioAssets.map((asset) => {
                if (asset.transactions.length > 0) {
                    return {
                        name: asset.name,
                        key: asset.uid,
                        amountView: (
                            <React.Fragment>
                                &#8377;&nbsp;
                                {getFormattedAmount(
                                    asset.portfolio.latest.investment
                                )}
                            </React.Fragment>
                        ),
                        amount: asset.portfolio.latest.investment,
                    };
                }
                return {
                    name: asset.name,
                    key: asset.uid,
                    amountView: <React.Fragment>&#8377;&nbsp;0</React.Fragment>,
                    amount: 0,
                };
            });
            return {
                portfolio: portfolio.name,
                uid: portfolio.uid,
                assets: updatedAssets.sort((a, b) => b.amount - a.amount),
            };
        });
        setInvestments(list.filter((l) => l.assets.length > 0));
    };

    const handleInvestmentListDrawer = () => {
        // close the drawer
        if (showInvestmentsDrawer) {
            setShowInvestmentsDrawer(false);
        }
        // open the drawer
        else {
            getTotalInvestmentList();
            setShowInvestmentsDrawer(true);
        }
    };

    const getAlreadyBackedUpData = async () => {
        setRefreshing(true);
        if (userInfo) {
            const data = await fetchBackup("users", userInfo);
            if (data) {
                dispatch(updateBackedupPortfolios(data.portfolios));
                dispatch(updateBackedupAssets(data.assets));
                dispatch(updateSettings(data.settings));
            } else {
                console.log("no backups");
            }
        }
        setRefreshing(false);
    };

    // const handleServicesClick = (appName) => {
    //     switch (appName) {
    //         case "auto-invest":
    //             setShowAutoInvestDrawer(true);
    //             break;
    //         case "import-binance":
    //             setShowAutoInvestDrawer(true);
    //             break;
    //         default:
    //             history.push("/");
    //             break;
    //     }
    // };

    useEffect(() => {
        const hasUserCookie = Cookies.get("me_apps_user");
        if (hasUserCookie) {
            setShowImportedPortfolios(true);
        }
        if (Number(login) === 1) {
            getAlreadyBackedUpData();
        } else if (!userInfo && hasUserCookie) {
            const parsedUser = JSON.parse(window.atob(hasUserCookie));
            dispatch(updateUser(parsedUser));
            setUserInfo(parsedUser);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setHeaderStats({
            ...headerStats,
            pl:
                headerStats.totalInvestment !== 0
                    ? ((headerStats.currentValue -
                          headerStats.totalInvestment) /
                          headerStats.totalInvestment) *
                      100
                    : 0,
        });
        // eslint-disable-next-line
    }, [headerStats.currentValue]);

    useEffect(() => {
        getAllPortfolioAssetsInfo();
    }, [JSON.stringify(portfolios)]);

    return (
        <div>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Title
                            level={4}
                            style={{
                                marginBottom: 0,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            Your Portfolios
                        </Title>
                        <div>
                            <Tooltip title={userInfo?.name?.fullName}>
                                <Avatar
                                    size={{
                                        xs: 24,
                                        sm: 32,
                                        md: 40,
                                    }}
                                    icon={
                                        <img
                                            src={userInfo?.picture}
                                            alt={userInfo?.name?.fullName}
                                        />
                                    }
                                />
                            </Tooltip>
                        </div>
                    </div>
                }
                placement="right"
                closable={false}
                height="100%"
                width="100%"
                open
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <Row>
                        <Col
                            sm={24}
                            lg={{ span: 8, offset: 8 }}
                            style={{ width: "-webkit-fill-available" }}
                        >
                            <div
                                style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        mpTapCreatePortfolioCTA();
                                        setShowAddPortfolioDrawer(true);
                                    }}
                                    block
                                >
                                    Create Portfolio
                                </Button>
                                <Loader
                                    handleClick={() => {
                                        mpTapRefreshCTA();
                                        getAllPortfolioAssetsInfo();
                                    }}
                                    loading={refreshing}
                                />
                                <Button
                                    disabled={refreshing}
                                    type="default"
                                    onClick={() => {
                                        mpTapInvestmentListViewCTA();
                                        handleInvestmentListDrawer();
                                    }}
                                    style={{ marginLeft: 8 }}
                                >
                                    {showInvestmentsDrawer ? (
                                        <DownOutlined />
                                    ) : (
                                        <UpOutlined />
                                    )}
                                </Button>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        mpTapSettingsCTA();
                                        handleSettingsDrawer();
                                    }}
                                    style={{ marginLeft: 8 }}
                                >
                                    {showSettings ? (
                                        <SettingFilled />
                                    ) : (
                                        <SettingOutlined />
                                    )}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                }
            >
                <Row>
                    <Col
                        sm={24}
                        lg={{ span: 8, offset: 8 }}
                        style={{ width: "-webkit-fill-available" }}
                    >
                        {/* Services */}
                        {/* <Divider orientation="left">Services</Divider>
                        <Services handleServicesClick={handleServicesClick} /> */}
                        {/* Imported Portfolios */}
                        {showImportedPortfolios && (
                            <React.Fragment>
                                <Divider orientation="left">Imported</Divider>
                                <Row
                                    onClick={() => handleBinanceDrawer()}
                                    style={{ cursor: "pointer" }}
                                >
                                    <Col span={24}>
                                        <ImportedPortfolioCard
                                            name="Binance"
                                            logo="https://www.logo.wine/a/logo/Binance/Binance-Logo.wine.svg"
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                        {refreshing && (
                            <Divider orientation="left">
                                <Space size="small">
                                    <div>Fetching Portfolios</div>
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                style={{
                                                    fontSize: 16,
                                                }}
                                                spin={refreshing}
                                            />
                                        }
                                    />
                                </Space>
                            </Divider>
                        )}
                        {/* Add sekeleton loader */}
                        {[1, 2, 3].map((index) => (
                            <Skeleton
                                loading={refreshing}
                                title
                                paragraph
                                active
                                key={index}
                                style={{ marginTop: 16 }}
                            />
                        ))}
                        {/* New Portfolios */}
                        {!refreshing && items.newPortfolios.length > 0 && (
                            <Divider orientation="left">New</Divider>
                        )}
                        {!refreshing &&
                            items.newPortfolios.length > 0 &&
                            items.newPortfolios.map(
                                (
                                    { name, uid, avatar, currentValue, pl },
                                    index
                                ) => {
                                    return (
                                        <OverviewCard
                                            itemInfo={{
                                                name,
                                                uid,
                                                avatar,
                                                handleMoreClk,
                                            }}
                                            itemData={{ currentValue, pl }}
                                            index={index}
                                            key={uid}
                                        />
                                    );
                                }
                            )}

                        {/* Active Portfolios */}
                        {!refreshing && items.activePortfolios.length > 0 && (
                            <Divider orientation="left">Active</Divider>
                        )}
                        {!refreshing &&
                            items.activePortfolios.length > 0 &&
                            items.activePortfolios.map(
                                (
                                    { name, uid, avatar, currentValue, pl },
                                    index
                                ) => {
                                    return (
                                        <OverviewCard
                                            itemInfo={{
                                                name,
                                                uid,
                                                avatar,
                                                handleMoreClk,
                                            }}
                                            itemData={{ currentValue, pl }}
                                            index={
                                                items.newPortfolios.length +
                                                index
                                            }
                                            key={uid}
                                        />
                                    );
                                }
                            )}

                        {/* Inactive Portfolios */}
                        {!refreshing && items.inactivePortfolios.length > 0 && (
                            <Divider orientation="left">Inactive</Divider>
                        )}
                        {!refreshing &&
                            items.inactivePortfolios.length > 0 &&
                            items.inactivePortfolios.map(
                                (
                                    { name, uid, avatar, currentValue, pl },
                                    index
                                ) => {
                                    return (
                                        <OverviewCard
                                            itemInfo={{
                                                name,
                                                uid,
                                                avatar,
                                                handleMoreClk,
                                            }}
                                            itemData={{ currentValue, pl }}
                                            index={
                                                items.newPortfolios.length +
                                                items.activePortfolios.length +
                                                index
                                            }
                                            key={uid}
                                        />
                                    );
                                }
                            )}

                        {!refreshing &&
                            !items.inactivePortfolios.length &&
                            !items.newPortfolios.length &&
                            !items.activePortfolios.length && (
                                <Result
                                    status="500"
                                    title="Hey, Welcome!"
                                    subTitle={
                                        "You don't have any portfolios yet."
                                    }
                                />
                            )}
                    </Col>
                </Row>
            </Drawer>
            <InvestmentList
                headerStats={headerStats}
                investments={investments}
                onClose={handleInvestmentListDrawer}
                visible={showInvestmentsDrawer}
                refreshing={refreshing}
                getAllPortfolioAssetsInfo={getAllPortfolioAssetsInfo}
            />
            <AddPortfolio
                showAddPortfolioDrawer={showAddPortfolioDrawer}
                onClose={setShowAddPortfolioDrawer}
            />
            <Settings
                visible={showSettings}
                onClose={() => setShowSettings(false)}
            />
            <AutoInvest
                visible={showAutoInvestDrawer}
                onClose={() => setShowAutoInvestDrawer(false)}
            />
        </div>
    );
};

export default Portfolios;

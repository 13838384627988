import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import _ from "lodash";
import { Form, Button, Drawer, AutoComplete, Row, Col } from "antd";
import Disclaimer from "Shared/Disclaimer";
import * as assetAction from "store/Actions/asset";
import * as portfolioAction from "store/Actions/portfolio";
import { mpTapAddCoin } from "Shared/Mixpanel/Assets";
import { getCoinsList } from "utils/CoinGecko";

const AddAsset = (props) => {
    const { visible = false, onClose = () => {} } = props;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const selectedPortfolio = useSelector(
        (state) => state.portfolios.selectedPortfolio
    );
    const cachedCoinList = useSelector((state) => state.portfolios.coinList);
    const [disabled, setDisabled] = useState(true);
    const [asset, setAsset] = useState("");
    const [coinList, setCoinList] = useState([]);
    const [options, setOptions] = useState([]);

    const getCoinList = async () => {
        const coins = await getCoinsList();
        const list = coins.map((coin) => {
            return { ...coin, value: coin.name, size: coin.name.length };
        });
        dispatch(portfolioAction.cacheCoinList(list));
        setCoinList(list);
    };
    useEffect(() => {
        if (cachedCoinList && cachedCoinList.length === 0) {
            getCoinList();
        } else {
            setCoinList(cachedCoinList);
        }
        // eslint-disable-next-line
    }, []);

    const handleValueChange = (coin) => {
        const selectedCoin = coinList.find((c) => c.value === coin);
        setAsset(selectedCoin);
        setDisabled(selectedCoin.name === "");
    };

    const onSearch = (searchText) => {
        const searchOptions = searchText
            ? coinList.filter((coin) =>
                  coin.name.toUpperCase().includes(searchText.toUpperCase())
              )
            : [];
        setOptions(_.sortBy(searchOptions, ["size"]));
    };

    const onFinish = () => {
        mpTapAddCoin({ name: asset.name });
        dispatch(
            assetAction.add({
                name: asset.name,
                id: asset.id,
                symbol: asset.symbol,
                avatar: "",
                portfolioId: selectedPortfolio.uid, // belongs to which portfolio
                uid: nanoid(),
                transactions: [],
                portfolio: {},
                createdAt: Date.now(),
            })
        );
        onClose();
    };

    const onFinishFailed = (error) => {
        console.log(`Error`, error);
    };
    return (
        <div>
            <Drawer
                title="Add Coin"
                placement="bottom"
                // closable={false}
                onClose={onClose}
                open={visible}
                width="100%"
                height="300px"
                maskClosable={false}
            >
                <Row>
                    <Col sm={24} md={{ span: 8, offset: 8 }}>
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={{ preserve: true }}
                            onValuesChange={handleValueChange}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            name="add-asset"
                            autoComplete="off"
                        >
                            <Form.Item label="Coin Name">
                                <AutoComplete
                                    options={options}
                                    style={{ width: "100%" }}
                                    onSelect={handleValueChange}
                                    onSearch={onSearch}
                                    placeholder="Dogecoin / Bitcoin / Ethereum"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    disabled={disabled}
                                    type="primary"
                                    htmlType="submit"
                                    block
                                >
                                    {`Add ${asset?.name ? asset.name : ""}`}
                                </Button>
                            </Form.Item>
                            <Disclaimer />
                        </Form>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
};

export default AddAsset;

import React from "react";
import { Col, Row, Space } from "antd";
import EmotionlessStats from "Shared/EmotionlessStats";
import { useSelector } from "react-redux";

const ViewPortfolio = (props) => {
    const { coinInfo = null } = props;
    const asset = useSelector((state) => state.assets.selectedAsset);
    const { portfolio = {} } = asset;

    return (
        <div>
            <Space
                direction="vertical"
                size={2}
                style={{
                    textAlign: "left",
                    width: "-webkit-fill-available",
                }}
            >
                <h2>Overview</h2>
                <Row>
                    <Col
                        sm={24}
                        style={{
                            width: "-webkit-fill-available",
                            display: "flex",
                        }}
                    >
                        <EmotionlessStats
                            title="Portfolio Value"
                            value={
                                coinInfo.current_price *
                                portfolio.latest.numberOfCoins
                            }
                        />
                        <EmotionlessStats
                            title="Holdings"
                            value={portfolio.latest.numberOfCoins}
                        />
                    </Col>
                    <Col
                        sm={24}
                        style={{
                            width: "-webkit-fill-available",
                            display: "flex",
                            padding: "1rem 0",
                        }}
                    >
                        <EmotionlessStats
                            title="Average Price"
                            value={portfolio.latest.marketPrice}
                        />
                        <EmotionlessStats
                            title="Your Investments"
                            value={portfolio.latest.investment}
                        />
                    </Col>
                </Row>
            </Space>
        </div>
    );
};

export default ViewPortfolio;

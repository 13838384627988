import React, { useState } from "react";
import { Form, Input, Button, Drawer, Row, Col, Space, Table } from "antd";
import {
    DollarOutlined,
    MinusCircleOutlined,
    PercentageOutlined,
    PlusOutlined,
} from "@ant-design/icons";

const AutoInvest = (props) => {
    const { visible, onClose } = props;
    const [data, setData] = useState([]);

    const onFinish = (value) => {
        const assets = value.assets.map((asset) => {
            return {
                name: asset.name,
                amount: asset.amount,
                amountInUSD: (value.amountToInvest * asset.amount) / 100,
            };
        });
        setData(assets);
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Amount in %",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Amount in USD",
            dataIndex: "amountInUSD",
            key: "amountInUSD",
        },
    ];

    return (
        <div>
            <Drawer
                title="Auto Invest"
                width="100%"
                height="80%"
                placement="bottom"
                onClose={onClose}
                open={visible}
            >
                <Row justify="center">
                    <Col xs={24} lg={{ span: 8, offset: 8 }}>
                        <Form
                            name="autoInvestForm"
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Amount to Invest"
                                name="amountToInvest"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please input amount to invest!",
                                    },
                                ]}
                            >
                                <Input suffix={<DollarOutlined />} />
                            </Form.Item>
                            <Form.List name="assets">
                                {(fields, { add, remove }) => (
                                    <React.Fragment>
                                        {fields.map(
                                            ({ key, name, ...restField }) => (
                                                <Space
                                                    key={key}
                                                    style={{
                                                        display: "flex",
                                                        marginBottom: 8,
                                                    }}
                                                    align="baseline"
                                                >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "name"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Missing asset name",
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Solana" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "amount"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Missing amount in %",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="25"
                                                            suffix={
                                                                <PercentageOutlined />
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <MinusCircleOutlined
                                                        onClick={() =>
                                                            remove(name)
                                                        }
                                                    />
                                                </Space>
                                            )
                                        )}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add Asset
                                            </Button>
                                        </Form.Item>
                                    </React.Fragment>
                                )}
                            </Form.List>
                            <Form.Item>
                                <Button type="primary" block htmlType="submit">
                                    Calculate
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col sm={24} md={{ span: 8, offset: 8 }}>
                        <Table dataSource={data} columns={columns} bordered />
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
};

export default AutoInvest;

import mixpanel from "mixpanel-browser";

export const mpTapGoBack = ({ screen }) => {
    mixpanel.track("GO_BACK", { screen });
};

export const mpTapBuyCTA = ({ type }) => {
    mixpanel.track("TAP_INTENT_BUY", { type }); // primary, secondary
};

export const mpTapSellCTA = (options) => {
    mixpanel.track("TAP_INTENT_SELL_CTA", options);
};

export const mpViewMarketData = ({ coin }) => {
    mixpanel.track("TAP_MARKET_DATA", { coin });
};

export const mpTapPreview = ({ type }) => {
    mixpanel.track("TAP_PREVIEW", {
        type,
    }); // type: buy/sell
};

export const mpTapAddTransaction = ({ type }) => {
    mixpanel.track("ADD_TRANSACTION", { type }); // type: buy/sell
};

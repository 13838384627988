import getActionType from "utils/getActionType";

const reducerName = "assets";

const actionTypes = {
    ADD_ASSET: getActionType(reducerName, "ADD_ASSET"),
    SET_PORTFOLIO_ASSETS: getActionType(reducerName, "SET_PORTFOLIO_ASSETS"),
    SET_SELECTED_ASSET: getActionType(reducerName, "SET_SELECTED_ASSET"),
    ADD_TRANSACTION: getActionType(reducerName, "ADD_TRANSACTION"),
    ADD_PORTFOLIO: getActionType(reducerName, "ADD_PORTFOLIO"),
    SET_SELECTED_PORTFOLIO: getActionType(
        reducerName,
        "SET_SELECTED_PORTFOLIO"
    ),
    ADD_ASSET_TO_PORTFOLIO: getActionType(
        reducerName,
        "ADD_ASSET_TO_PORTFOLIO"
    ),
    CACHE_COIN_LIST: getActionType(reducerName, "CACHE_COIN_LIST"),
    HOLD_MY_RESULT: getActionType(reducerName, "HOLD_MY_RESULT"),
    BUY: getActionType(reducerName, "BUY"),
    SELL: getActionType(reducerName, "SELL"),
    UPDATE_THEME: getActionType(reducerName, "UPDATE_THEME"),
    UPDATE_USER: getActionType(reducerName, "UPDATE_USER"),
    PERFORM_BACKUP: getActionType(reducerName, "PERFORM_BACKUP"),
    UPDATE_BACKEDUP_PORTFOLIO: getActionType(
        reducerName,
        "UPDATE_BACKEDUP_PORTFOLIO"
    ),
    UPDATE_BACKEDUP_ASSETS: getActionType(
        reducerName,
        "UPDATE_BACKEDUP_ASSETS"
    ),
    UPDATE_SETTINGS: getActionType(reducerName, "UPDATE_SETTINGS"),
    UPDATE_LAST_BACKUP: getActionType(reducerName, "UPDATE_LAST_BACKUP"),
    DELETE_PORTFOLIO: getActionType(reducerName, "DELETE_PORTFOLIO"),
};

export default actionTypes;

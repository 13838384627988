import React, { useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router";
import Typewriter from "typewriter-effect";
import { Form, Button, Drawer, Row, Col, Typography, Input } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const News = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState(true);
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState("Waiting for your query...");

    const handleResponseDisplay = () => (
        <Typewriter
            options={{
                strings: [response],
                autoStart: true,
                loop: true,
                delay: 20,
                pauseFor: 600000,
            }}
        />
    );

    const handleValueChange = (data) => {
        setQuery(data.target.value);
        setDisabled(data.target.value < 20);
    };

    const goBackCTA = () => history.push("/imported/binance");

    const onFinish = async () => {
        setLoading(true);
        setDisabled(true);

        const api = `${process.env.REACT_APP_API}/binance/news?q=${query}`;
        const apiResponse = await Axios.get(api);
        const { aiResponse } = apiResponse.data;
        setResponse(aiResponse);

        setLoading(false);
        setDisabled(false);
    };

    const onFinishFailed = (error) => {
        console.log(`Error`, error);
    };

    return (
        <div>
            <Drawer
                title="Asking AI"
                placement="right"
                closable
                onClose={goBackCTA}
                closeIcon={<ArrowLeftOutlined />}
                open
                width="100%"
                height="100%"
            >
                <Row>
                    <Col
                        xs={24}
                        lg={{ span: 8, offset: 8 }}
                        style={{
                            width: "-webkit-fill-available",
                            marginRight: "24px",
                            marginBottom: "224px",
                        }}
                    >
                        <Typography.Text
                            style={{ whiteSpace: "pre-line", fontSize: 16 }}
                        >
                            {handleResponseDisplay()}
                        </Typography.Text>
                    </Col>
                    <Col
                        xs={24}
                        lg={{ span: 8, offset: 8 }}
                        style={{
                            height: "200px",
                            position: "fixed",
                            bottom: 0,
                            width: "-webkit-fill-available",
                            marginRight: "24px",
                            borderTop: "1px solid #e5e5e5",
                        }}
                    >
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={{ preserve: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            name="ask-ai"
                            autoComplete="off"
                        >
                            <Form.Item>
                                <Input
                                    placeholder="Get market sentiments"
                                    bordered={false}
                                    onChange={handleValueChange}
                                    showCount
                                    maxLength={1000}
                                    style={{
                                        height: "100px",
                                    }}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    disabled={disabled}
                                    type="primary"
                                    htmlType="submit"
                                    block
                                    loading={loading}
                                >
                                    Query
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
};

export default News;

import produce from "immer";
import actionTypes from "../Actions/types";

const initialState = {
    theme: {
        isAuto: true,
        label: "dark",
    },
    currency: "INR",
    lastBackup: 0,
};

const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.UPDATE_SETTINGS:
                draft.theme = action.payload.theme;
                draft.lastBackup = action.payload.lastBackup;
                draft.currency = action.payload.currency;
                draft.currencyExchangeRate = action.payload.currencyExchangeRate;
                break;
            case actionTypes.UPDATE_THEME:
                draft.theme = action.payload;
                break;
            case actionTypes.UPDATE_LAST_BACKUP:
                draft.lastBackup = action.payload;
                break;
            default:
                return draft;
        }
    });
export default reducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Button, Drawer, Skeleton, Result, Row, Col, Typography } from "antd";
import * as assetAction from "store/Actions/asset";
import * as portfolioAction from "store/Actions/portfolio";
import OverviewCard from "Components/Dashboard/OverviewCard";
import { getCoinInfo } from "utils/CoinGecko";
import { orderBy } from "lodash";
import AddAsset from "Components/Forms/AddAsset";
import { mpTapAddCoinCTA, mpTapGoBack } from "Shared/Mixpanel/Assets";
import { mpTapRefreshCTA, mpTapViewMoreCTA } from "Shared/Mixpanel/Portfolios";
import Loader from "Shared/Loader";
import { DeleteOutlined } from "@ant-design/icons";

const Assets = () => {
    const history = useHistory();
    const { search } = useLocation();
    const intent = new URLSearchParams(search).get("intent");
    const dispatch = useDispatch();
    const selectedPortfolio = useSelector(
        (state) => state.portfolios.selectedPortfolio
    );
    document.title = `${selectedPortfolio.name} - Portfolio App`;
    const assets = useSelector((state) => state.assets.assets);
    dispatch(assetAction.setPortfolioAssets(selectedPortfolio));

    const [items, setItems] = useState([]);
    const [showAddAssetDrawer, setShowAddAssetDrawer] = useState(
        intent === "add"
    );
    const [loading, setLoading] = useState(true);

    const handleMoreClk = (uid) => {
        const asset = assets.find((item) => item.uid === uid);
        mpTapViewMoreCTA({ name: asset.name });
        dispatch(assetAction.setSelectedAsset(asset));
        history.push(
            `/portfolios/${selectedPortfolio.name}/assets/${asset.id}`
        );
    };

    const deletePortfolio = () => {
        dispatch(portfolioAction.deletePortfolio(selectedPortfolio));
        history.push("/portfolios");
    };

    const getAssetInfo = async () => {
        setLoading(true);
        const currentPortfolioAssets = assets.filter(
            (asset) => asset.portfolioId === selectedPortfolio.uid
        );
        const coinsMarketData = await getCoinInfo(currentPortfolioAssets);
        const coins = currentPortfolioAssets.map((asset) => {
            let currentValue = 0;
            let totalInvestment = 0;
            let avatar = "";
            const coinInfo = coinsMarketData.find(
                (data) => data.id === asset.id
            );
            if (coinInfo) {
                if (asset.transactions.length > 0) {
                    currentValue +=
                        (coinInfo.current_price || 0) *
                        asset.portfolio.latest.numberOfCoins;
                    totalInvestment += asset.portfolio.latest.investment;
                }
                avatar = coinInfo.image;
                const pl =
                    totalInvestment !== 0
                        ? ((currentValue - totalInvestment) / totalInvestment) *
                          100
                        : 0;
                return {
                    ...asset,
                    currentValue,
                    totalInvestment,
                    avatar,
                    pl,
                };
            }
            return {
                ...asset,
            };
        });
        const data = coins.filter((coin) => coin.avatar);
        const currentInvestmentAmount = data.reduce(
            (accumulator, currentValue) =>
                accumulator + currentValue.totalInvestment,
            0
        );
        const nonZeroAssets = orderBy(
            data.filter((asset) => asset.totalInvestment > 0),
            ["pl"],
            ["desc"]
        ).map((asset) => {
            return {
                ...asset,
                investmentPie: (
                    asset.totalInvestment / currentInvestmentAmount
                ).toFixed(2),
            };
        });
        const otherAssets = data
            .filter((asset) => asset.totalInvestment === 0)
            .reverse();
        setItems([...nonZeroAssets, ...otherAssets]);
        setLoading(false);
    };

    useEffect(() => {
        getAssetInfo();
        // eslint-disable-next-line
    }, [JSON.stringify(assets)]);

    return (
        <div>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "baseline",
                            }}
                        >
                            <div style={{ fontSize: "14px", color: "#fff" }}>
                                Your coins in&nbsp;
                            </div>
                            <Typography.Title
                                level={4}
                                style={{
                                    color: "#fff",
                                    marginBottom: "0 !important",
                                    fontWeight: 600,
                                }}
                            >
                                {selectedPortfolio.name}
                            </Typography.Title>
                        </div>
                        <div>
                            <Button
                                onClick={deletePortfolio}
                                type="danger"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                size="medium"
                            />
                        </div>
                    </div>
                }
                placement="right"
                closable={false}
                height="100%"
                width="100%"
                open
                headerStyle={{
                    zIndex: "2",
                    margin: "auto 0",
                }}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <Row>
                        <Col
                            sm={24}
                            lg={{ span: 8, offset: 8 }}
                            style={{ width: "-webkit-fill-available" }}
                        >
                            <div
                                style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                        fontSize: "12px",
                                    }}
                                    type="ghost"
                                    onClick={() => {
                                        mpTapGoBack({
                                            screen: "ALL_ASSETS_SCREEN",
                                        });
                                        history.push(`/portfolios`);
                                    }}
                                >
                                    Back
                                </Button>

                                <Button
                                    type="primary"
                                    onClick={() => {
                                        mpTapAddCoinCTA();
                                        setShowAddAssetDrawer(true);
                                    }}
                                    block
                                >
                                    Add Coin
                                </Button>
                                <Loader
                                    handleClick={() => {
                                        mpTapRefreshCTA({ content: "Assets" });
                                        getAssetInfo();
                                    }}
                                    loading={loading}
                                />
                            </div>
                        </Col>
                    </Row>
                }
            >
                <Row>
                    <Col
                        sm={24}
                        lg={{ span: 8, offset: 8 }}
                        style={{ width: "-webkit-fill-available" }}
                    >
                        {[1, 2, 3, 4, 5].map((index) => (
                            <Skeleton
                                loading={loading}
                                title
                                paragraph
                                active
                                avatar
                                key={index}
                            />
                        ))}
                        {!loading &&
                            items.length > 0 &&
                            items.map(
                                (
                                    {
                                        name,
                                        uid,
                                        avatar,
                                        currentValue,
                                        pl,
                                        investmentPie,
                                    },
                                    index
                                ) => {
                                    return (
                                        <OverviewCard
                                            itemInfo={{
                                                name,
                                                uid,
                                                avatar,
                                                handleMoreClk,
                                            }}
                                            itemData={{
                                                currentValue,
                                                pl,
                                                investmentPie,
                                            }}
                                            index={index}
                                            key={uid}
                                        />
                                    );
                                }
                            )}
                        {!loading && items.length === 0 && (
                            <Result
                                status="500"
                                title="Your coins come in here"
                                subTitle={"You haven't baught any coins yet."}
                            />
                        )}
                    </Col>
                </Row>
            </Drawer>

            <AddAsset
                visible={showAddAssetDrawer}
                onClose={() => setShowAddAssetDrawer(false)}
            />
        </div>
    );
};

export default Assets;

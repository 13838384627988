import { ReloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const Loader = (props) => {
    const { type = "default", handleClick = () => {}, loading = false } = props;
    return (
        <div>
            <Button
                type={type}
                onClick={() => handleClick()}
                style={{ marginLeft: 8, color: "#fff" }}
                disabled={loading}
            >
                <ReloadOutlined spin={loading} />
            </Button>
        </div>
    );
};

export default Loader;

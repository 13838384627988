import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "id-portfolio-app.firebaseapp.com",
    projectId: "id-portfolio-app",
    storageBucket: "id-portfolio-app.appspot.com",
    messagingSenderId: "790078902148",
    appId: "1:790078902148:web:981f95ed337885ccffd7d1",
    measurementId: "G-RSRJEFZD6G",
};

initializeApp(firebaseConfig);

export const db = getFirestore();
